import { useContext, useState } from "react";

import {
    BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID,
    COACH_PLATFORM_PERMISSIONS,
    PRINTABLE_SURVEY_REPORT_COMMENTS_THEMES_TYPES,
    PRINTABLE_SURVEY_REPORT_TYPES,
} from "../../../../../../../utils/constants";
import { Context } from "../../../../../../../context/Context";

import DashboardLayout from "../../../../../../SharedComponents/DashboardLayout";
import BouncingBalls from "../../../../../../SharedComponents/Loading/BouncingBalls";
import SingleWithBenchmark from "../ChartCards/SingleWithBenchmark";
import SatisfactionOnSixMainDimensions from "../ChartCards/SatisfactionOnSixMainDimensions";
import PerDimensionItemsRegional from "./Sentiment/PerDimensionItemsRegional";
import QualitativeItemSection from "./Qualitative/QualitativeItemSection";
import RadioButton from "../../../../../../SharedComponents/Form/RadioButton";
import { DemographicsElements } from "./Demographics";
import { ACEKeyThemesElement } from "./ACEKeyThemes";
import { FieldManagerSatisfactionElements } from "./FieldManagerSatisfaction/FieldManagerSatisfaction";
import { ServicesElements } from "./Services/Services";
import { QualitativeElements } from "./Qualitative/Qualitative";
import { FollowThroughElements } from "./FollowThrough";
import { ReportContextElements } from "./ReportContext";
import { MemberQuestionsElements } from "./MemberQuestions/MemberQuestions";
import QuestionsDataChart from "../ChartCards/QuestionsDataChart";
import { AdditionalQuestionsElements } from "./Qualitative/AdditionalQuestions";

export default function PrintableSurvey() {
    const context = useContext(Context);

    const [reportType, setReportType] = useState(PRINTABLE_SURVEY_REPORT_TYPES.all);
    const [commentsThemes, setCommentsThemes] = useState(PRINTABLE_SURVEY_REPORT_COMMENTS_THEMES_TYPES.onlyComments);

    let memberTypeTitle = "Member Type";

    if (
        context.aceSurveyReportData.sixDimensionsMemberTypes[0] &&
        context.aceSurveyReportData.sixDimensionsMemberTypes[0].title
    ) {
        memberTypeTitle = context.aceSurveyReportData.sixDimensionsMemberTypes[0].title.split("-")[1].trim();
    }

    let sixDimensionsByMemberTypeTitle = "Six dimensions by " + memberTypeTitle;
    let sixDimensionsByAreaTitle = "Six dimensions by area (" + memberTypeTitle + ")";

    let franchisorDisagreementChartDataItem = [];

    if (context.aceSurveyReportData.franchisorTeamNotificationOfSeriousDisagreement.length !== 0) {
        context.aceSurveyReportData.franchisorTeamNotificationOfSeriousDisagreement.forEach((disagreementItem) => {
            disagreementItem.items.map((disagreementItemItem) =>
                franchisorDisagreementChartDataItem.push({
                    itemText: disagreementItemItem.itemText,
                    data: disagreementItemItem.data
                        .map((item) => ({
                            name: item.name,
                            percentScore: item.percentScore.Yes,
                            category: item.name,
                        }))
                        .concat(
                            disagreementItemItem.historicalScore.reverse().map((item) => ({
                                name: item.name,
                                percentScore: item.percentScore,
                                category: item.name,
                            }))
                        ),
                })
            );
        });
    }

    return (
        <DashboardLayout requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_REPORTS]}>
            {context.aceSurveyReportDataLoading ? (
                <div className={"col-span-full flex items-center justify-center p-8 w-full"}>
                    <BouncingBalls />
                </div>
            ) : (
                <div className="flex flex-col gap-10 print:gap-0">
                    <div className="flex flex-col gap-2 w-full print:hidden">
                        <div className={"flex justify-between w-full"}>
                            <label className="text-sm font-medium text-primary-600 w-full ">Report type</label>
                        </div>
                        <fieldset>
                            <div
                                className="flex flex-row gap-6"
                                onChange={(event) => {
                                    setReportType(event.target.value);
                                }}
                            >
                                <RadioButton
                                    id={PRINTABLE_SURVEY_REPORT_TYPES.all}
                                    name="reportType"
                                    value={PRINTABLE_SURVEY_REPORT_TYPES.all}
                                    labelText="All"
                                    defaultChecked
                                />
                                <RadioButton
                                    id={PRINTABLE_SURVEY_REPORT_TYPES.national}
                                    name="reportType"
                                    value={PRINTABLE_SURVEY_REPORT_TYPES.national}
                                    labelText="National"
                                />
                                <RadioButton
                                    id={PRINTABLE_SURVEY_REPORT_TYPES.regional}
                                    name="reportType"
                                    value={PRINTABLE_SURVEY_REPORT_TYPES.regional}
                                    labelText="Regional"
                                />
                                <RadioButton
                                    id={PRINTABLE_SURVEY_REPORT_TYPES.nationalAndRegional}
                                    name="reportType"
                                    value={PRINTABLE_SURVEY_REPORT_TYPES.nationalAndRegional}
                                    labelText="National and Regional"
                                />
                                <RadioButton
                                    id={PRINTABLE_SURVEY_REPORT_TYPES.fieldManagers}
                                    name="reportType"
                                    value={PRINTABLE_SURVEY_REPORT_TYPES.fieldManagers}
                                    labelText="Field managers"
                                />
                                <RadioButton
                                    id={PRINTABLE_SURVEY_REPORT_TYPES.qualitative}
                                    name="reportType"
                                    value={PRINTABLE_SURVEY_REPORT_TYPES.qualitative}
                                    labelText="Qualitative"
                                />
                            </div>
                        </fieldset>
                        <div className={"flex justify-between w-full"}>
                            <label className="text-sm font-medium text-primary-600 w-full ">
                                Themes and comments options
                            </label>
                        </div>
                        <fieldset>
                            <div
                                className="flex flex-row gap-6"
                                onChange={(event) => {
                                    setCommentsThemes(event.target.value);
                                }}
                            >
                                <RadioButton
                                    id={PRINTABLE_SURVEY_REPORT_COMMENTS_THEMES_TYPES.onlyComments}
                                    name="commentsThemes"
                                    value={PRINTABLE_SURVEY_REPORT_COMMENTS_THEMES_TYPES.onlyComments}
                                    labelText="Only comments"
                                />
                                <RadioButton
                                    id={PRINTABLE_SURVEY_REPORT_COMMENTS_THEMES_TYPES.onlyThemes}
                                    name="commentsThemes"
                                    value={PRINTABLE_SURVEY_REPORT_COMMENTS_THEMES_TYPES.onlyThemes}
                                    labelText="Only themes"
                                />
                            </div>
                        </fieldset>
                    </div>

                    {/* Intro pages */}
                    <div id="executiveSummary" className="flex flex-col gap-3 hidden">
                        <h1 className="text-xl font-medium text-primary-600 tracking-tight font-title">
                            Executive summary
                        </h1>
                        <div className="flex flex-col gap-3">
                            <h2 className="text-lg text-secondary-600 font-medium font-title tracking-tight">
                                Key areas
                            </h2>
                            <div
                                className={"text-sm"}
                                dangerouslySetInnerHTML={{ __html: context.aceSurveyReportData.executiveSummary }}
                            />
                        </div>
                    </div>
                    <div id="demographics" className="flex flex-col gap-3 print:break-before-page hidden">
                        <h1 className="text-xl font-medium text-primary-600 tracking-tight font-title">Demographics</h1>
                        <DemographicsElements />
                    </div>
                    <div id="reportContext" className="flex flex-col gap-3 print:break-before-page hidden">
                        <h1 className="text-xl font-medium text-primary-600 tracking-tight font-title">
                            Report Context
                        </h1>
                        <ReportContextElements />
                    </div>

                    {/*Add print:break-before-page when done for albaik below*/}
                    {/* Sentiment */}
                    <div
                        id="sentiment"
                        className={
                            "flex flex-col gap-3 print:-mt-5 break-before-page " +
                            (reportType === PRINTABLE_SURVEY_REPORT_TYPES.national ||
                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.nationalAndRegional ||
                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.regional ||
                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.qualitative ||
                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.all
                                ? "flex"
                                : "hidden")
                        }
                    >
                        <h1 className="text-xl font-medium text-primary-600 tracking-tight font-title">Sentiment</h1>
                        {(reportType === PRINTABLE_SURVEY_REPORT_TYPES.national ||
                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.nationalAndRegional ||
                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.regional ||
                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.all) && (
                            <div
                                className={
                                    "flex flex-col gap-8 " +
                                    (reportType === PRINTABLE_SURVEY_REPORT_TYPES.national ||
                                    reportType === PRINTABLE_SURVEY_REPORT_TYPES.nationalAndRegional ||
                                    reportType === PRINTABLE_SURVEY_REPORT_TYPES.regional ||
                                    reportType === PRINTABLE_SURVEY_REPORT_TYPES.all
                                        ? "flex"
                                        : "hidden")
                                }
                            >
                                <div className={"flex flex-col gap-8 print:break-inside-avoid-page"}>
                                    <SingleWithBenchmark
                                        // noInfoBubbles
                                        // noInfoBubbleOnPrint
                                        onlyBubbleOnFirstBarOnPrint
                                        infoBubble
                                        withBenchmarks
                                        detailed
                                        data={
                                            context.aceSurveyReportData.generalSatisfaction.find(
                                                (item) => item.name === "General Satisfaction"
                                            ).data
                                        }
                                    />
                                    {context.aceSurveyReportData.sixDimensionsMemberTypes &&
                                        context.aceSurveyReportData.sixDimensionsMemberTypes.length !== 0 &&
                                        context.aceSurveyReportData.generalSatisfactionWithMemberTypes.map(
                                            (generalSatisfactionItem) =>
                                                generalSatisfactionItem.name !== "Text" && (
                                                    <SingleWithBenchmark
                                                        onlyBubbleOnFirstBarOnPrint
                                                        infoBubble
                                                        withBenchmarks
                                                        detailed
                                                        data={generalSatisfactionItem.data}
                                                    />
                                                )
                                        )}
                                </div>

                                <div className={"flex flex-col gap-4 break-before-page"}>
                                    <SatisfactionOnSixMainDimensions
                                        // noInfoBubbles
                                        withBenchmarks
                                        detailed
                                        data={context.aceSurveyReportData.sixDimensions}
                                    />
                                </div>
                                <div className={"flex flex-col gap-4 print:break-inside-avoid-page"}>
                                    <PerDimensionItemsRegional
                                        // noInfoBubbleOnPrint
                                        // noInfoBubbles
                                        displayOnlyContent
                                        displayOverall
                                        displayProportions={
                                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.national ||
                                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.nationalAndRegional ||
                                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.all
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        {(reportType === PRINTABLE_SURVEY_REPORT_TYPES.nationalAndRegional ||
                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.all) &&
                            context.aceSurveyReportData.franchisorTeamNotificationOfSeriousDisagreement.map(
                                (disagreementItem) =>
                                    disagreementItem.name ===
                                        "Notified the franchisor team of serious disagreement" && (
                                        <QuestionsDataChart
                                            data={franchisorDisagreementChartDataItem}
                                            title={disagreementItem.name}
                                            colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID}
                                            questions
                                            infoBubble
                                            noInfoBubbleOnPrint
                                            extraStyling="print:break-before-page"
                                            percentageAxisLabels={{
                                                first: "0%",
                                                second: "25%",
                                                third: "50%",
                                                fourth: "75%",
                                                fifth: "100%",
                                            }}
                                            percentage
                                            noTrendLegend
                                            percentageAxisMargin
                                        />
                                    )
                            )}

                        {context.aceSurveyReportData.sixDimensionsMemberTypes &&
                            context.aceSurveyReportData.sixDimensionsMemberTypes.length !== 0 &&
                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.all && (
                                <div className={"flex flex-col gap-4 break-before-page mt-8"}>
                                    <PerDimensionItemsRegional
                                        data={context.aceSurveyReportData.sixDimensionsWithMemberTypes}
                                        displayOnlyContent
                                        displayOverall
                                    />
                                </div>
                            )}

                        {/*{(reportType === PRINTABLE_SURVEY_REPORT_TYPES.nationalAndRegional ||*/}
                        {/*    reportType === PRINTABLE_SURVEY_REPORT_TYPES.all) && (*/}
                        {/*    <SentimentOvertime*/}
                        {/*        hasSixDimensionsRegionalHistoricalData*/}
                        {/*        hasGeneralSatisfactionHistoricalData*/}
                        {/*        hasSixDimensionsHistoricalData*/}
                        {/*        displayOnlyContent*/}
                        {/*    />*/}
                        {/*)}*/}

                        <div
                            className={
                                reportType === PRINTABLE_SURVEY_REPORT_TYPES.qualitative ||
                                reportType === PRINTABLE_SURVEY_REPORT_TYPES.all
                                    ? "flex"
                                    : "hidden"
                            }
                        >
                            {context.aceSurveyReportData.generalSatisfaction.map(
                                (generalSatisfactionItem) =>
                                    generalSatisfactionItem.name === "Text" && (
                                        <div
                                            className={
                                                "flex flex-col gap-4 w-full bg-slate-100 p-4 rounded-xl " +
                                                "border border-slate-200 shadow-sm print:p-0 " +
                                                "print:shadow-none print:bg-white print:border-0 break-before-page"
                                            }
                                        >
                                            <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight">
                                                Open-ended questions
                                            </h2>
                                            <div className="flex flex-col gap-4 print:gap-0">
                                                {generalSatisfactionItem.items.map((item, index) => (
                                                    <QualitativeItemSection
                                                        key={index + "qualitativeItemSection"}
                                                        data={item}
                                                        title={"Open-ended question #" + (index + 1)}
                                                        firstItem={index === 0}
                                                        onlyComments={commentsThemes === "onlyComments"}
                                                        onlyThemes={commentsThemes === "onlyThemes"}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    )
                            )}
                        </div>
                    </div>

                    {/* ACE Key Themes */}
                    <div
                        id="aceKeyThemes"
                        className={
                            "flex flex-col gap-3 print:break-before-page " +
                            (reportType === PRINTABLE_SURVEY_REPORT_TYPES.national ||
                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.nationalAndRegional ||
                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.qualitative ||
                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.all
                                ? "flex"
                                : "hidden")
                        }
                    >
                        <h1 className="text-xl font-medium text-primary-600 tracking-tight font-title">
                            ACE Key Themes
                        </h1>
                        {(reportType === PRINTABLE_SURVEY_REPORT_TYPES.national ||
                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.nationalAndRegional ||
                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.all) && (
                            <div
                                className={
                                    reportType === PRINTABLE_SURVEY_REPORT_TYPES.national ||
                                    reportType === PRINTABLE_SURVEY_REPORT_TYPES.nationalAndRegional ||
                                    reportType === PRINTABLE_SURVEY_REPORT_TYPES.all
                                        ? "flex"
                                        : "hidden"
                                }
                            >
                                <ACEKeyThemesElement displayOnlyContent noOpenEndedQuestions />
                            </div>
                        )}
                        <div
                            className={
                                reportType === PRINTABLE_SURVEY_REPORT_TYPES.qualitative ||
                                reportType === PRINTABLE_SURVEY_REPORT_TYPES.all
                                    ? "flex"
                                    : "hidden"
                            }
                        >
                            {context.aceSurveyReportData.netPromoterScores.map(
                                (netPromoterScoreItem) =>
                                    netPromoterScoreItem.name === "Text" && (
                                        <div
                                            className={
                                                "flex flex-col gap-4 w-full bg-slate-100 p-4 rounded-xl border " +
                                                "border-slate-200 shadow-sm print:p-0 print:shadow-none print:bg-white " +
                                                "print:border-0 break-before-page "
                                            }
                                        >
                                            <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight">
                                                Open-ended questions
                                            </h2>
                                            <div className="flex flex-col gap-4 print:gap-0">
                                                {netPromoterScoreItem.items.map((item, index) => (
                                                    <QualitativeItemSection
                                                        key={index + "NPSScoreItem"}
                                                        data={item}
                                                        title={"Open-ended question #" + (index + 1)}
                                                        firstItem={index === 0}
                                                        onlyComments={commentsThemes === "onlyComments"}
                                                        onlyThemes={commentsThemes === "onlyThemes"}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    )
                            )}
                        </div>
                    </div>

                    {/* Field Managers */}
                    <div
                        id="fieldManagers"
                        className={
                            "flex flex-col gap-4 print:break-before-page " +
                            (reportType === PRINTABLE_SURVEY_REPORT_TYPES.fieldManagers ||
                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.qualitative
                                ? // reportType === PRINTABLE_SURVEY_REPORT_TYPES.nationalAndRegional
                                  "flex"
                                : "hidden")
                        }
                    >
                        <h1 className="text-xl font-medium text-primary-600 tracking-tight font-title">
                            Field Manager Satisfaction
                        </h1>
                        {(reportType === PRINTABLE_SURVEY_REPORT_TYPES.fieldManagers ||
                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.all ||
                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.nationalAndRegional) && (
                            <div
                                className={
                                    reportType === PRINTABLE_SURVEY_REPORT_TYPES.fieldManagers ||
                                    reportType === PRINTABLE_SURVEY_REPORT_TYPES.all ||
                                    reportType === PRINTABLE_SURVEY_REPORT_TYPES.nationalAndRegional
                                        ? "flex"
                                        : "hidden"
                                }
                            >
                                <FieldManagerSatisfactionElements displayOnlyContent noOpenEndedQuestions />
                            </div>
                        )}
                        <div
                            className={
                                reportType === PRINTABLE_SURVEY_REPORT_TYPES.qualitative ||
                                reportType === PRINTABLE_SURVEY_REPORT_TYPES.all
                                    ? "flex flex-col gap-4"
                                    : "hidden"
                            }
                        >
                            {context.aceSurveyReportData.fieldManagers.map(
                                (fieldManagerItem, index) =>
                                    fieldManagerItem.name === "Text" && (
                                        <div
                                            key={index + "openEndedQuestionsSection"}
                                            className={
                                                "flex flex-col gap-4 w-full bg-slate-100 p-4 rounded-xl border border-slate-200 shadow-sm " +
                                                "print:p-0 print:shadow-none print:border-none print:bg-white"
                                            }
                                        >
                                            <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight">
                                                Open-ended questions
                                            </h2>
                                            <div className="flex flex-col gap-4 print:gap-0">
                                                {fieldManagerItem.items.map((item, index) => (
                                                    <QualitativeItemSection
                                                        key={index + "qualitativeItemSection"}
                                                        data={item}
                                                        title={"Open-ended question #" + (index + 1)}
                                                        noPrintStyles
                                                        onlyComments={commentsThemes === "onlyComments"}
                                                        onlyThemes={commentsThemes === "onlyThemes"}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    )
                            )}

                            {context.aceSurveyReportData.fieldManagersCountry.length > 0 &&
                                context.aceSurveyReportData.fieldManagersCountry.map(
                                    (fieldManagerItem, index) =>
                                        fieldManagerItem.name === "Text" && (
                                            <div
                                                key={index + "openEndedQuestionsSection"}
                                                className={
                                                    "flex flex-col gap-4 w-full bg-slate-100 p-4 rounded-xl border border-slate-200 shadow-sm " +
                                                    "print:p-0 print:shadow-none print:border-none print:bg-white break-before-page"
                                                }
                                            >
                                                <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight">
                                                    Open-ended questions
                                                </h2>
                                                <div className="flex flex-col gap-4 print:gap-0">
                                                    {fieldManagerItem.items.map((item, index) => (
                                                        <QualitativeItemSection
                                                            key={index + "qualitativeItemSection"}
                                                            data={item}
                                                            title={"Open-ended question #" + (index + 1)}
                                                            noPrintStyles
                                                            onlyComments={commentsThemes === "onlyComments"}
                                                            onlyThemes={commentsThemes === "onlyThemes"}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        )
                                )}
                        </div>
                    </div>

                    {/* Services */}
                    {context.aceSurveyReportData.services.length !== 0 && (
                        <div
                            id="services"
                            className={
                                "flex flex-col gap-3 break-before-page " +
                                (reportType === PRINTABLE_SURVEY_REPORT_TYPES.national ||
                                reportType === PRINTABLE_SURVEY_REPORT_TYPES.nationalAndRegional ||
                                reportType === PRINTABLE_SURVEY_REPORT_TYPES.all
                                    ? "flex flex-col gap-4"
                                    : "hidden")
                            }
                        >
                            <h1 className="text-xl font-medium text-primary-600 tracking-tight font-title">Services</h1>
                            <ServicesElements displayOnlyContent noQualitative />
                        </div>
                    )}

                    {(reportType === PRINTABLE_SURVEY_REPORT_TYPES.qualitative ||
                        reportType === PRINTABLE_SURVEY_REPORT_TYPES.all) &&
                        context.aceSurveyReportData.services.map(
                            (service) =>
                                service.name === "Text" && (
                                    <div className={"flex flex-col gap-4 break-before-page"}>
                                        <h1 className="text-xl font-medium text-primary-600 tracking-tight font-title">
                                            Services
                                        </h1>
                                        <div className={"flex flex-col gap-4 w-full print:gap-0"}>
                                            <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight">
                                                Open-ended questions
                                            </h2>
                                            <div className="flex flex-col gap-4 print:gap-0">
                                                {service.items.map(
                                                    (item, index) =>
                                                        item.itemName !== "QUAL_BEST" &&
                                                        item.itemName !== "QUAL_WORST" && (
                                                            <QualitativeItemSection
                                                                key={index + "openEndedItemSection"}
                                                                data={item}
                                                                title={"Open-ended question #" + (index + 1)}
                                                                onlyComments={commentsThemes === "onlyComments"}
                                                                onlyThemes={commentsThemes === "onlyThemes"}
                                                                firstItem={index === 0}
                                                            />
                                                        )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                        )}

                    {/* Additional Questions */}
                    {(reportType === PRINTABLE_SURVEY_REPORT_TYPES.all ||
                        reportType === PRINTABLE_SURVEY_REPORT_TYPES.qualitative) && (
                        <div className={"flex flex-col gap-4 break-before-page"}>
                            <h1 className="text-xl font-medium text-primary-600 tracking-tight font-title">
                                Additional Questions
                            </h1>
                            <AdditionalQuestionsElements
                                displayOnlyContent
                                onlyComments={commentsThemes === "onlyComments"}
                                onlyThemes={commentsThemes === "onlyThemes"}
                            />
                        </div>
                    )}

                    {/* Member Questions */}
                    {context.aceSurveyReportData.memberQuestions.length !== 0 && (
                        <div
                            id="memberQuestions"
                            className={
                                "flex flex-col gap-3 print:break-before-page " +
                                (reportType === PRINTABLE_SURVEY_REPORT_TYPES.national ||
                                reportType === PRINTABLE_SURVEY_REPORT_TYPES.nationalAndRegional ||
                                reportType === PRINTABLE_SURVEY_REPORT_TYPES.all ||
                                reportType === PRINTABLE_SURVEY_REPORT_TYPES.qualitative
                                    ? "flex"
                                    : "hidden")
                            }
                        >
                            <h1 className="text-xl font-medium text-primary-600 tracking-tight font-title">
                                Member Questions
                            </h1>
                            {(reportType === PRINTABLE_SURVEY_REPORT_TYPES.national ||
                                reportType === PRINTABLE_SURVEY_REPORT_TYPES.nationalAndRegional ||
                                reportType === PRINTABLE_SURVEY_REPORT_TYPES.all) && (
                                <MemberQuestionsElements displayOnlyContent noInfoBubbles />
                            )}

                            {reportType === PRINTABLE_SURVEY_REPORT_TYPES.qualitative &&
                                context.aceSurveyReportData.memberQuestions
                                    .find(
                                        (memberQuestionsElement) =>
                                            memberQuestionsElement.name ===
                                            "Conditions for continuing terms with supplier"
                                    )
                                    .items.map((memberQuestionsItem, index) => (
                                        <QualitativeItemSection
                                            key={index}
                                            data={{ responseTexts: memberQuestionsItem.otherTexts }}
                                            title='Conditions for continuing terms with supplier - "Other" responses'
                                            noContainer
                                            firstItem={index === 0}
                                            onlyComments={commentsThemes === "onlyComments"}
                                            onlyThemes={commentsThemes === "onlyThemes"}
                                        />
                                    ))}
                        </div>
                    )}

                    {/* Qualitative */}
                    <div
                        id="qualitative"
                        className={
                            "flex flex-col gap-3 print:break-before-page " +
                            (reportType === PRINTABLE_SURVEY_REPORT_TYPES.qualitative ||
                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.all ||
                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.nationalAndRegional
                                ? "flex"
                                : "hidden")
                        }
                    >
                        <h1 className="text-xl font-medium text-primary-600 tracking-tight font-title">Qualitative</h1>
                        <QualitativeElements
                            displayOnlyContent
                            onlyComments={commentsThemes === "onlyComments"}
                            onlyThemes={commentsThemes === "onlyThemes"}
                        />
                    </div>

                    {/* Follow-through */}
                    <div
                        id="followThrough"
                        className={
                            "flex flex-col gap-3 print:break-before-page " +
                            (reportType === PRINTABLE_SURVEY_REPORT_TYPES.national ||
                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.nationalAndRegional ||
                            reportType === PRINTABLE_SURVEY_REPORT_TYPES.all
                                ? "flex"
                                : "hidden")
                        }
                    >
                        <h1 className="text-xl font-medium text-primary-600 tracking-tight font-title">
                            Follow-through
                        </h1>
                        <FollowThroughElements />
                    </div>
                </div>
            )}
        </DashboardLayout>
    );
}
