import SausageLegendItem from "./CustomLegends/SausageLegendItem";
import TrendBubble from "../../SharedChartComponents/TrendBubble";
import { useContext } from "react";
import { Context } from "../../../../../../context/Context";
import OverallAgreeanceLegend from "../../SharedChartComponents/OverallAgreeanceLegend";

export default function StackedBarsInfoBubble(props) {
    const { data, showWhenPrinting } = props;

    const context = useContext(Context);

    return (
        <div
            className={
                "flex-col gap-4 rounded-xl p-3.5 bg-white shadow-lg border border-slate-200 font-normal " +
                (showWhenPrinting ? "hidden print:flex " : "flex print:hidden ") +
                "print:shadow-none print:bg-slate-50 print:mr-[52px] print:gap-2 print:w-fit print:p-2"
            }
        >
            <span className={"text-base font-medium text-primary-600 print:hidden"}>{data && data.itemTitle}</span>
            <div className="flex flex-col gap-4 justify-between print:flex-row">
                {data.data.current && data.historicalPercentScores && (
                    <div className="grid grid-cols-2 print:grid-cols-4 gap-4 print:place-items-end print:flex print:flex-row print:gap-3 items-center">
                        <span className="text-sm text-slate-500 font-medium print:text-sm print:hidden">
                            Overall agree percentage change
                        </span>
                        <div
                            className={
                                "flex flex-col gap-2 border border-y-0 border-r-0 border-l-2 " +
                                ((data.data &&
                                    data.data.current.percentScores.overallAgreePercentage -
                                        data.historicalPercentScores.overallAgreePercentage) === 0
                                    ? "border-slate-500 text-slate-500"
                                    : data.data.current.percentScores.overallAgreePercentage -
                                          data.historicalPercentScores.overallAgreePercentage >
                                      0
                                    ? "border-green-500 text-green-500"
                                    : "border-red-500 text-red-500")
                            }
                        >
                            <div className="flex flex-col print:flex-row print:items-center gap-2 pl-2">
                                <span className="flex flex-row gap-2 text-base font-medium"></span>
                                <TrendBubble
                                    percentage={
                                        data.data &&
                                        data.data.current.percentScores.overallAgreePercentage -
                                            data.historicalPercentScores.overallAgreePercentage
                                    }
                                    small
                                />
                                <span className="text-xs text-slate-500">% point change</span>
                            </div>
                        </div>
                        <div className="flex flex-col print:flex-row gap-2 pl-2 print:items-center">
                            <span className="text-base font-medium text-primary-600">
                                {data && data.data.current.percentScores.overallAgreePercentage}%
                            </span>
                            <span className="text-xs text-slate-500">
                                {context.aceSurveyReportData.currentSurveyLabel}
                            </span>
                        </div>
                        <div className="flex flex-col print:flex-row gap-2 pl-2 print:items-center">
                            <span className="text-base font-medium text-primary-600">
                                {data && data.historicalPercentScores.overallAgreePercentage}%
                            </span>
                            <span className="text-xs text-slate-500">
                                {context.aceSurveyReportData.previousSurveyLabel}
                            </span>
                        </div>
                        <div className="print:flex hidden print:items-center">
                            <OverallAgreeanceLegend noContainer />
                        </div>
                    </div>
                )}
                <div className="grid grid-cols-2 print:grid-cols-4 gap-3 pl-2 print:items-center print:hidden">
                    <span className="col-span-2 text-sm text-slate-500 font-medium print:hidden">All scores</span>
                    <div className="flex flex-col gap-2 print:items-center">
                        <span className="flex flex-row gap-2 text-base font-medium text-primary-600">
                            <SausageLegendItem backgroundColour="bg-red-500" hideOnPrint />
                            {data && data.data.current.percentScores.stronglyDisagreePercentage}%
                        </span>
                        <span className="text-xs text-slate-500">Strongly disagree</span>
                    </div>
                    <div className="flex flex-col gap-2 print:items-center">
                        <span className="flex flex-row gap-2 text-base font-medium text-primary-600">
                            <SausageLegendItem backgroundColour="bg-orange-500" hideOnPrint />
                            {data && data.data.current.percentScores.disagreePercentage}%
                        </span>
                        <span className="text-xs text-slate-500">Disagree</span>
                    </div>
                    <div className="flex flex-col gap-2 print:items-center">
                        <span className="flex flex-row gap-2 text-base font-medium text-primary-600">
                            <SausageLegendItem backgroundColour="bg-teal-500" hideOnPrint />
                            {data && data.data.current.percentScores.agreePercentage}%
                        </span>
                        <span className="text-xs text-slate-500">Agree</span>
                    </div>
                    <div className="flex flex-col gap-2 print:items-center">
                        <span className="flex flex-row gap-2 text-base font-medium text-primary-600">
                            <SausageLegendItem backgroundColour="bg-green-500" hideOnPrint />
                            {data && data.data.current.percentScores.stronglyAgreePercentage}%
                        </span>
                        <span className="text-xs text-slate-500">Strongly agree</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
