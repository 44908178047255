import { useContext, useEffect, useState } from "react";

import { Context } from "../../../../../../../../context/Context";
import {
    BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID,
    BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE,
    COACH_PLATFORM_PERMISSIONS,
} from "../../../../../../../../utils/constants";

import DashboardLayout from "../../../../../../../SharedComponents/DashboardLayout";
import TabbedNavigationLayout from "../../../../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import QualitativeItemSection from "./QualitativeItemSection";
import BouncingBalls from "../../../../../../../SharedComponents/Loading/BouncingBalls";
import QuestionsDataChart from "../../ChartCards/QuestionsDataChart";
import SatisfactionScaleVerticalScores from "../../ChartCards/SatisfactionScaleVerticalScores";
import AceReportPicker from "../AceReportPicker";

export default function AdditionalQuestions() {
    return (
        <DashboardLayout
            title="Additional Questions"
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_GROUP_REPORTS]}
            otherElements={<AceReportPicker small />}
        >
            <AdditionalQuestionsElements />
        </DashboardLayout>
    );
}

export function AdditionalQuestionsElements(props) {
    const { displayOnlyContent, onlyComments, onlyThemes } = props;

    const context = useContext(Context);

    const [tabIndex, setTabIndex] = useState(0);
    const [tabList, setTabList] = useState([]);
    const [tabContent, setTabContent] = useState([]);

    useEffect(() => {
        if (context.aceSurveyReportData.qualitative.length !== 0) {
            let updatedTabList = [];
            let updatedTabContent = [];

            context.aceSurveyReportData.additionalQuestions.map((additionalQuestion) =>
                additionalQuestion.name === "Additional Questions" && !onlyComments && !onlyThemes
                    ? updatedTabList.push(additionalQuestion.name) &&
                      updatedTabContent.push({
                          title: additionalQuestion.name,
                          content: (
                              <QuestionsDataChart
                                  data={additionalQuestion.items}
                                  title={additionalQuestion.name}
                                  colourPalette={BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE}
                                  noItemTitles
                                  topXAxis
                                  questions
                                  infoBubble
                                  noInfoBubbleOnPrint
                              />
                          ),
                      })
                    : additionalQuestion.name === "Text"
                    ? updatedTabList.push("Open-ended questions") &&
                      updatedTabContent.push({
                          content: (
                              <div className="flex flex-col gap-3">
                                  {additionalQuestion.items.map((item, index) => (
                                      <QualitativeItemSection
                                          key={index + "qualitativeItemSectionQualitative"}
                                          data={item}
                                          title={"Open-ended question #" + (index + 1)}
                                          onlyComments={onlyComments}
                                          onlyThemes={onlyThemes}
                                          firstItem={index === 0}
                                      />
                                  ))}
                              </div>
                          ),
                      })
                    : additionalQuestion.name === "Store Partner Relationship"
                    ? updatedTabList.unshift("Store Partner Relationship") &&
                      updatedTabContent.unshift({
                          title: additionalQuestion.name,
                          content: <SatisfactionScaleVerticalScores data={additionalQuestion.items[0]} noExtraInfo />,
                      })
                    : additionalQuestion.name === "Miscellaneous Question" && !onlyComments && !onlyThemes
                    ? updatedTabList.unshift(additionalQuestion.title) &&
                      updatedTabContent.unshift({
                          content: (
                              <QuestionsDataChart
                                  data={additionalQuestion.items.map((memberQuestionsItem) => ({
                                      itemText: memberQuestionsItem.itemText,
                                      data: Object.entries(memberQuestionsItem.score).map(([name, percentScore]) => ({
                                          name,
                                          percentScore,
                                          category: name,
                                      })),
                                  }))}
                                  title={additionalQuestion.title}
                                  colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID}
                                  questions
                                  infoBubble
                                  noInfoBubbleOnPrint
                                  // noInfoBubbles={noInfoBubbles}
                                  extraStyling="print:break-before-page"
                                  percentageAxisLabels={{
                                      first: "0%",
                                      second: "25%",
                                      third: "50%",
                                      fourth: "75%",
                                      fifth: "100%",
                                  }}
                                  percentage
                                  noTrendLegend
                                  percentageAxisMargin
                              />
                          ),
                      })
                    : additionalQuestion.name === "Personally Coping" &&
                      !onlyComments &&
                      !onlyThemes &&
                      updatedTabList.push("Personally Coping") &&
                      updatedTabContent.push({
                          title: additionalQuestion.name,
                          content: (
                              <div className="flex flex-col gap-3">
                                  {additionalQuestion.items.map((item, index) => (
                                      <QuestionsDataChart
                                          key={index}
                                          data={Object.keys(item.score).map((key) => ({
                                              itemTitle: additionalQuestion.itemText,
                                              itemText: key,
                                              data: [
                                                  {
                                                      name: context.aceSurveyReportData.currentSurveyLabel,
                                                      percentScore: item.score[key],
                                                      category: context.aceSurveyReportData.currentSurveyLabel,
                                                      historicalScore: item.historicalScore[key],
                                                  },
                                              ],
                                          }))}
                                          title={item.itemText}
                                          colourPalette={BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE}
                                          noItemTitles
                                          topXAxis
                                          questions
                                          infoBubble
                                          noInfoBubbleOnPrint
                                      />
                                  ))}
                              </div>
                          ),
                      })
            );

            setTabList(updatedTabList);
            setTabContent(updatedTabContent);
        }
    }, [context.aceSurveyReportData, onlyComments, onlyThemes]);

    return (
        <div className="flex flex-col gap-4">
            {context.aceSurveyReportDataLoading ? (
                <div className={"col-span-full flex items-center justify-center p-8 w-full"}>
                    <BouncingBalls />
                </div>
            ) : (
                <TabbedNavigationLayout
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                    tabList={tabList}
                    backgroundColour={"bg-white"}
                    tabContent={tabContent}
                    noContainer
                    displayOnlyContent={displayOnlyContent}
                    tabsRowOnly
                />
            )}
        </div>
    );
}
