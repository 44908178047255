import { useContext, useEffect, useState } from "react";

import { Context } from "../../../../../../../../context/Context";
import { COACH_PLATFORM_PERMISSIONS } from "../../../../../../../../utils/constants";
import { createFieldManagerTabContent } from "../../../../../../../../utils/utils";

import DashboardLayout from "../../../../../../../SharedComponents/DashboardLayout";
import TabbedNavigationLayout from "../../../../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import BouncingBalls from "../../../../../../../SharedComponents/Loading/BouncingBalls";
import AceReportPicker from "../AceReportPicker";

export default function FieldManagerSatisfaction(props) {
    return (
        <DashboardLayout
            title="Field manager satisfaction"
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_GROUP_REPORTS]}
            otherElements={<AceReportPicker small />}
        >
            <FieldManagerSatisfactionElements props={props} />
        </DashboardLayout>
    );
}

export function FieldManagerSatisfactionElements(props) {
    const { displayOnlyContent, noOpenEndedQuestions, onlyComments, onlyThemes } = props;

    const [tabIndexParent, setTabIndexParent] = useState(0);
    const [tabListParent, setTabListParent] = useState([]);
    const [tabContentParent, setTabContentParent] = useState([]);

    const [tabIndexRegional, setTabIndexRegional] = useState(0);

    const [tabIndexCountry, setTabIndexCountry] = useState(0);

    const context = useContext(Context);

    useEffect(() => {
        if (
            context.aceSurveyReportData.fieldManagersCountry.length !== 0 &&
            context.aceSurveyReportData.fieldManagers.length !== 0
        ) {
            let updatedTabListParent = [];
            let updatedTabContentParent = [];

            updatedTabListParent.push(
                context.aceSurveyReportData.fieldManagers[0].categoryTitle
                    ? context.aceSurveyReportData.fieldManagers[0].categoryTitle
                    : "Field Managers"
            );

            updatedTabListParent.push(
                context.aceSurveyReportData.fieldManagersCountry[0].categoryTitle
                    ? context.aceSurveyReportData.fieldManagersCountry[0].categoryTitle
                    : "Field Managers"
            );

            const tabUpdatedRegional = createFieldManagerTabContent(
                context.aceSurveyReportData.fieldManagers,
                context.aceSurveyReportData.fieldManagersLevel,
                noOpenEndedQuestions,
                displayOnlyContent,
                onlyComments,
                onlyThemes
            );

            const tabUpdatedCountry = createFieldManagerTabContent(
                context.aceSurveyReportData.fieldManagersCountry,
                context.aceSurveyReportData.fieldManagersLevelCountry,
                noOpenEndedQuestions,
                displayOnlyContent,
                onlyComments,
                onlyThemes
            );

            updatedTabContentParent.push({
                content: (
                    <TabbedNavigationLayout
                        tabIndex={tabIndexRegional}
                        setTabIndex={setTabIndexRegional}
                        tabList={tabUpdatedRegional.tabList}
                        backgroundColour={"bg-white"}
                        noContainer
                        tabContent={tabUpdatedRegional.tabContent}
                        displayOnlyContent={displayOnlyContent}
                        secondaryTabs
                        tabsRowOnly
                    />
                ),
                readonly: true,
            });

            updatedTabContentParent.push({
                content: (
                    <TabbedNavigationLayout
                        tabIndex={tabIndexCountry}
                        setTabIndex={setTabIndexCountry}
                        tabList={tabUpdatedCountry.tabList}
                        backgroundColour={"bg-white"}
                        noContainer
                        tabContent={tabUpdatedCountry.tabContent}
                        displayOnlyContent={displayOnlyContent}
                        secondaryTabs
                        tabsRowOnly
                    />
                ),
                readonly: true,
            });

            setTabListParent(updatedTabListParent);
            setTabContentParent(updatedTabContentParent);
        } else {
            let fieldManagerData = [];
            let fieldManagerLevelData = [];

            if (context.aceSurveyReportData.fieldManagersCountry.length !== 0) {
                fieldManagerData = context.aceSurveyReportData.fieldManagersCountry;
                fieldManagerLevelData = context.aceSurveyReportData.fieldManagersLevelCountry;
            } else if (context.aceSurveyReportData.fieldManagers.length !== 0) {
                fieldManagerData = context.aceSurveyReportData.fieldManagers;
                fieldManagerLevelData = context.aceSurveyReportData.fieldManagersLevel;
            }

            const tabUpdatedParent = createFieldManagerTabContent(
                fieldManagerData,
                fieldManagerLevelData,
                noOpenEndedQuestions,
                displayOnlyContent
            );

            setTabListParent(tabUpdatedParent.tabList);
            setTabContentParent(tabUpdatedParent.tabContent);
        }
    }, [
        context.aceSurveyReportData.fieldManagers,
        context.aceSurveyReportData.fieldManagersLevel,
        context.aceSurveyReportData.fieldManagersCountry,
        context.aceSurveyReportData.fieldManagersLevelCountry,
        context.aceSurveyReportDataLoading,
        displayOnlyContent,
        noOpenEndedQuestions,
        tabIndexRegional,
        tabIndexCountry,
    ]);

    return (
        <div className="flex flex-col gap-4">
            <h2 className="text-lg text-secondary-600 font-medium font-title tracking-tight">
                Feedback on support team members
            </h2>
            {context.aceSurveyReportDataLoading ? (
                <div className={"col-span-full flex items-center justify-center p-8 w-full"}>
                    <BouncingBalls />
                </div>
            ) : (
                <TabbedNavigationLayout
                    tabIndex={tabIndexParent}
                    setTabIndex={setTabIndexParent}
                    tabList={tabListParent}
                    backgroundColour={"bg-white"}
                    noContainer
                    tabContent={tabContentParent}
                    displayOnlyContent={displayOnlyContent}
                    secondaryTabs
                />
            )}
        </div>
    );
}
