import { useContext } from "react";

import { Context } from "../../../../../../../../context/Context";
import { ACE_PRODUCTS_HIDE_FIELD_MANAGER, COACH_PLATFORM_PERMISSIONS } from "../../../../../../../../utils/constants";

import BouncingBalls from "../../../../../../../SharedComponents/Loading/BouncingBalls";
import BasicMotion from "../../../../../../../SharedComponents/Motion/BasicMotion";
import CompletionsByRegion from "./CompletionsByRegion";
import CompletionsByFieldManager from "./CompletionsByFieldManager";
import CompletionsByGroup from "./CompletionsByGroup";

export default function Completions() {
    const context = useContext(Context);

    return (
        <div className="flex flex-col gap-6">
            {context.aceSurveyRegionalCompletionDataLoading ? (
                <div className={"col-span-full flex items-center justify-center pt-10 pb-12 w-full"}>
                    <BouncingBalls />
                </div>
            ) : (
                <>
                    <div className="col-span-4 flex flex-col gap-4 break-inside-avoid-page">
                        {context.aceSurveyReportDataLoading ? (
                            <div className={"col-span-full flex items-center justify-center p-8 w-full"}>
                                <BouncingBalls />
                            </div>
                        ) : (
                            <>
                                <div className="flex flex-col gap-2">
                                    <div className={"flex flex-row justify-between items-center"}>
                                        <h2 className="text-lg text-secondary-600 font-medium font-title tracking-tight">
                                            {ACE_PRODUCTS_HIDE_FIELD_MANAGER.includes(
                                                context.aceSurveyCompletionsProductName
                                            )
                                                ? "Completion"
                                                : "Completions"}
                                        </h2>
                                        {context.organisationWorkflowACEProducts.length > 1 && (
                                            <BasicMotion>
                                                <div className={"flex flex-col gap-2 print:hidden"}>
                                                    <select
                                                        className={
                                                            "text-secondary-600 cursor-pointer hover:text-secondary-700 bg-secondary-50 " +
                                                            "rounded-xl shadow-sm flex font-medium " +
                                                            "bg-secondary-100 rounded-xl shadow-sm pr-10 text-left " +
                                                            "focus:outline-none outline-none ring-0 " +
                                                            "sm:text-sm border-2 border-secondary-100 focus:border-secondary-300 " +
                                                            "p-1.5 max-w-xs text-ellipsis"
                                                        }
                                                        onChange={(e) =>
                                                            context.handleACEDataCompletions(e.target.value)
                                                        }
                                                        value={context.aceSurveyCompletionsProductName}
                                                    >
                                                        {context.organisationWorkflowACEProducts.map(
                                                            (workflowACEProduct, index) => (
                                                                <option
                                                                    className={"text-ellipsis overflow-hidden"}
                                                                    key={index}
                                                                    value={workflowACEProduct.productName}
                                                                >
                                                                    {workflowACEProduct.productName}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>
                                            </BasicMotion>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-6">
                                    <CompletionsByRegion completions={context.aceSurveyRegionalCompletionData} />
                                    {!ACE_PRODUCTS_HIDE_FIELD_MANAGER.includes(
                                        context.aceSurveyCompletionsProductName
                                    ) && (
                                        <CompletionsByFieldManager
                                            completions={context.aceSurveyFieldManagerCompletionData}
                                        />
                                    )}
                                    {context.userPermissions.includes(
                                        COACH_PLATFORM_PERMISSIONS.VIEW_GROUP_COMPLETIONS
                                    ) && <CompletionsByGroup completions={context.aceSurveyGroupCompletionData} />}
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
