import {
    BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID,
    BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID_REVERSE_ORDER,
} from "../../../../../../../utils/constants";

import BarChart from "../../../Charts/CustomCharts/BarChart";

export default function SingleWithBenchmark(props) {
    const {
        data,
        demo,
        detailed,
        title,
        reversePalette,
        axisLabels,
        onlyBubbleOnFirstBarOnPrint,
        infoBubble,
        noInfoBubbleOnPrint,
        barChartFilterText,
        showResponses,
        infoBubblePlacement,
        noTrendLegend,
        noInfoBubbles,
        noteworthyTrend,
    } = props;

    return (
        <div
            className={
                "flex flex-col gap-6 w-full bg-white p-4 rounded-xl border border-slate-100 shadow-sm " +
                "print:p-0 print:shadow-none print:border-none"
            }
        >
            <div className="flex flex-col gap-3">
                <BarChart
                    noInfoBubbles={noInfoBubbles}
                    noInfoBubbleOnPrint={noInfoBubbleOnPrint}
                    infoBubble={infoBubble}
                    onlyBubbleOnFirstBarOnPrint={onlyBubbleOnFirstBarOnPrint}
                    emphasiseSectorBenchmark
                    title={title ? title : "General satisfaction"}
                    subtitle={
                        demo && detailed
                            ? "This shows an average of responses to all statements in the " +
                              "Sentiment section. It could be viewed as an overall measure of satisfaction."
                            : data && data.description
                    }
                    colourPalette={
                        reversePalette
                            ? BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID_REVERSE_ORDER
                            : BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID
                    }
                    data={data}
                    axisLabels={axisLabels}
                    filterText={barChartFilterText}
                    showResponses={showResponses}
                    infoBubblePlacement={infoBubblePlacement}
                    noTrendLegend={noTrendLegend}
                    singleNoteworthyTrend={noteworthyTrend}
                />
            </div>
        </div>
    );
}
