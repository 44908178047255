import { useContext, useState } from "react";

import { Context } from "../../../../../../../../context/Context";
import { AXIS_LABELS } from "../../../../../../../../utils/constants";

import TabbedNavigationLayout from "../../../../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import SingleWithBenchmark from "../../ChartCards/SingleWithBenchmark";

export default function IndividualFieldManagerComparison(props) {
    const { data, displayOnlyContent } = props;

    const [tabIndex, setTabIndex] = useState(0);
    const [fieldManagerTypeTabIndex, setFieldManagerTypeTabIndex] = useState(0);

    const context = useContext(Context);

    const tabList = ["Business capability", "Relationship capability"];
    const fieldManagerTypeTabList = [];

    data.business.data.map(
        (fieldManagerItem) =>
            !fieldManagerTypeTabList.includes(fieldManagerItem.type) &&
            fieldManagerItem.type !== undefined &&
            fieldManagerTypeTabList.push(fieldManagerItem.type)
    );

    data.relationship.data.map(
        (fieldManagerItem) =>
            !fieldManagerTypeTabList.includes(fieldManagerItem.type) &&
            fieldManagerItem.type !== undefined &&
            fieldManagerTypeTabList.push(fieldManagerItem.type)
    );

    const fieldManagerTypeContent = [];

    fieldManagerTypeTabList.map((tabListItem) =>
        fieldManagerTypeContent.push({
            content: (
                <TabbedNavigationLayout
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                    tabList={tabList}
                    backgroundColour={"bg-white"}
                    tabContent={[
                        {
                            content: (
                                <div className="flex flex-col gap-4 print:break-inside-avoid-page">
                                    <SingleWithBenchmark
                                        withBenchmarks
                                        detailed
                                        data={data.business.data.filter(
                                            (businessDataItem) =>
                                                businessDataItem.type === tabListItem ||
                                                businessDataItem.name === "Sector benchmark" ||
                                                businessDataItem.name === context.aceSurveyReportData.currentSurveyLabel
                                        )}
                                        title={"Field Manager Comparison - Business Capability"}
                                        reversePalette
                                        axisLabels={AXIS_LABELS.stronglyDissatisfied}
                                        infoBubble
                                        noInfoBubbleOnPrint
                                        barChartFilterText={"Field managers"}
                                    />
                                </div>
                            ),
                            readonly: true,
                        },
                        {
                            content: (
                                <div className="flex flex-col gap-4 print:break-inside-avoid-page">
                                    <SingleWithBenchmark
                                        withBenchmarks
                                        detailed
                                        data={data.relationship.data.filter(
                                            (businessDataItem) =>
                                                businessDataItem.type === tabListItem ||
                                                businessDataItem.name === "Sector benchmark" ||
                                                businessDataItem.name === context.aceSurveyReportData.currentSurveyLabel
                                        )}
                                        title={"Field Manager Comparison - Relationship Capability"}
                                        reversePalette
                                        axisLabels={AXIS_LABELS.stronglyDissatisfied}
                                        infoBubble
                                        noInfoBubbleOnPrint
                                        barChartFilterText={"Field managers"}
                                    />
                                </div>
                            ),
                            readonly: true,
                        },
                    ]}
                    tabsRowOnly
                    secondaryTabs
                    gapLessTabsAndConten
                    noContainer
                    displayOnlyContent={displayOnlyContent}
                />
            ),
            readonly: true,
        })
    );

    const tabContent = [
        {
            content: (
                <div className="flex flex-col gap-4 print:break-before-page">
                    <SingleWithBenchmark
                        withBenchmarks
                        detailed
                        data={data.business.data}
                        title={"Field Manager Comparison - Business Capability"}
                        reversePalette
                        axisLabels={AXIS_LABELS.stronglyDissatisfied}
                        infoBubble
                        noInfoBubbleOnPrint
                        barChartFilterText={"Field managers"}
                    />
                </div>
            ),
            readonly: true,
        },
        {
            content: (
                <div className="flex flex-col gap-4 print:break-before-page">
                    <SingleWithBenchmark
                        withBenchmarks
                        detailed
                        data={data.relationship.data}
                        title={"Field Manager Comparison - Relationship Capability"}
                        reversePalette
                        axisLabels={AXIS_LABELS.stronglyDissatisfied}
                        infoBubble
                        noInfoBubbleOnPrint
                        barChartFilterText={"Field managers"}
                    />
                </div>
            ),
            readonly: true,
        },
    ];

    return fieldManagerTypeTabList.length === 0 ? (
        <TabbedNavigationLayout
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            tabList={tabList}
            backgroundColour={"bg-white"}
            tabContent={tabContent}
            tabsRowOnly
            secondaryTabs
            gapLessTabsAndContent
            noContainer
            displayOnlyContent={displayOnlyContent}
        />
    ) : (
        <TabbedNavigationLayout
            tabIndex={fieldManagerTypeTabIndex}
            setTabIndex={setFieldManagerTypeTabIndex}
            tabList={fieldManagerTypeTabList}
            backgroundColour={"bg-white"}
            tabContent={fieldManagerTypeContent}
            tabsRowOnly
            secondaryTabs
            noContainer
            displayOnlyContent={displayOnlyContent}
        />
    );
}
