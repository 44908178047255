import { useContext, useEffect, useState } from "react";

import { Context } from "../../../../../../context/Context";

import TrendBubble from "../../SharedChartComponents/TrendBubble";

export default function BarInfoBubble(props) {
    const { data, noBenchmarkData, parentData, singleBar, showWhenPrinting, staticPosition, testId } = props;

    const [quintileScore, setQuintileScore] = useState();
    const [currentScoreItem, setCurrentScoreItem] = useState();
    const [currentSurveyLabel, setCurrentSurveyLabel] = useState("Current survey");
    const [previousSurveyLabel, setPreviousSurveyLabel] = useState("Previous survey");

    const context = useContext(Context);

    useEffect(() => {
        if (context.aceSurveyReportData) {
            setCurrentSurveyLabel(context.aceSurveyReportData.currentSurveyLabel);
            setPreviousSurveyLabel(context.aceSurveyReportData.previousSurveyLabel);
        }

        let currentScoreItem;

        if (data.hasOwnProperty("data")) {
            currentScoreItem = data.data.find((dataItem) => dataItem.category === currentSurveyLabel);
        } else {
            currentScoreItem = data;
        }

        setCurrentScoreItem(currentScoreItem);
        let sectorBenchmarkQuintileItem;

        if (data.hasOwnProperty("data")) {
            sectorBenchmarkQuintileItem = data.data.find((dataItem) => dataItem.category === "Sector benchmark");
        } else if (singleBar && currentScoreItem.name === currentSurveyLabel && parentData) {
            if (parentData.hasOwnProperty("data")) {
                sectorBenchmarkQuintileItem = parentData.data.find(
                    (dataItem) => dataItem.category === "Sector benchmark"
                );
            } else {
                sectorBenchmarkQuintileItem = parentData.find((dataItem) => dataItem.category === "Sector benchmark");
            }
        }

        if (sectorBenchmarkQuintileItem) {
            if (sectorBenchmarkQuintileItem.hasOwnProperty("quintile")) {
                setQuintileScore(sectorBenchmarkQuintileItem.quintile);
            }
        }
    }, [context.aceSurveyReportData, currentSurveyLabel, data, data.data, noBenchmarkData, parentData, singleBar]);

    return (
        currentScoreItem &&
        currentScoreItem.name !== "Sector benchmark" && (
            <div
                className={
                    "flex-col gap-4 rounded-xl p-3.5 border border-slate-200 font-normal print:p-2   " +
                    (showWhenPrinting ? "hidden print:flex " : "flex print:hidden ") +
                    "print:shadow-none print:bg-slate-50 print:mr-[52px] print:gap-2 print:p-3 print:w-fit " +
                    (staticPosition ? "bg-slate-50 shadow-none w-fit " : "bg-white shadow-lg ")
                }
                data-testid={testId && testId}
            >
                {currentScoreItem.textColour && (
                    <span
                        className={
                            "text-base font-medium print:text-sm print:hidden text-" +
                            (currentScoreItem.textColour &&
                                currentScoreItem.textColour.replace(/text-|-950/g, "") + "-600")
                        }
                    >
                        {currentScoreItem && currentScoreItem.name}
                    </span>
                )}
                <div
                    className={
                        "flex gap-4 justify-between print:flex-row " + (staticPosition ? "flex-row" : "flex-col")
                    }
                >
                    <div className="grid grid-cols-2 print:flex print:flex-row gap-4">
                        <div className={"flex gap-2 pl-2 flex-col"}>
                            <span className="text-base font-medium text-primary-600">
                                {currentScoreItem && currentScoreItem.percentScore}%
                            </span>
                            <span className="text-xs text-slate-500">{currentSurveyLabel}</span>
                        </div>
                        {currentScoreItem.historicalScore && (
                            <div className="flex flex-col gap-2 pl-2">
                                <span className="text-base font-medium text-primary-600">
                                    {currentScoreItem && currentScoreItem.historicalScore}%
                                </span>
                                <span className="text-xs text-slate-500">{previousSurveyLabel}</span>
                            </div>
                        )}
                        {currentScoreItem.historicalScore && (
                            <div
                                className={
                                    "flex flex-col gap-2 border border-y-0 border-r-0 border-l-2 pl-2 h-max " +
                                    (currentScoreItem &&
                                    currentScoreItem.percentScore - currentScoreItem.historicalScore === 0
                                        ? "border-slate-500 text-slate-500"
                                        : currentScoreItem.percentScore - currentScoreItem.historicalScore > 0
                                        ? "border-green-500 text-green-600"
                                        : "border-red-500 text-red-500")
                                }
                            >
                                <TrendBubble
                                    percentage={
                                        currentScoreItem &&
                                        currentScoreItem.percentScore - currentScoreItem.historicalScore
                                    }
                                    small
                                />
                                <span className="text-xs text-slate-500">% point change</span>
                            </div>
                        )}
                    </div>
                    {quintileScore && (
                        <div className="flex flex-col gap-2 pl-2 print:flex-col-reverse h-max">
                            <span className="text-sm text-slate-500 font-medium print:text-xs print:font-normal">
                                Comparison to sector benchmark
                            </span>
                            <span className="text-sm text-slate-500">
                                <div
                                    className={
                                        "flex flex-row items-center gap-2 rounded-xl w-max py-1 px-1.5 bg-slate-100 " +
                                        "border border-slate-400 text-slate-500"
                                    }
                                >
                                    <span className={"font-medium text-xs"}>
                                        <span className="font-normal text-xs">{quintileScore}</span>
                                    </span>
                                </div>
                            </span>
                        </div>
                    )}
                    {(data.benchmarkSizeDifference || data.historicalSizeDifference) && (
                        <div className={"flex flex-col gap-2 pl-2 print:flex-col-reverse h-max"}>
                            <span className="text-sm text-slate-500 font-medium print:text-xs print:font-normal print:hidden">
                                Effect sizes
                            </span>
                            <div className="grid grid-cols-2 gap-2">
                                {data.benchmarkSizeDifference && (
                                    <div
                                        className={
                                            "flex gap-2 flex-col " +
                                            (currentScoreItem.historicalScore ? "flex-row" : "flex-col")
                                        }
                                    >
                                        <div
                                            className={
                                                "flex flex-row items-center gap-2 rounded-xl w-max py-1 px-1.5 bg-slate-100 border border-slate-400 text-slate-500"
                                            }
                                        >
                                            <span className={"font-medium text-xs"}>
                                                <span className="font-normal text-xs">
                                                    {data.benchmarkSizeDifference}
                                                </span>
                                            </span>
                                        </div>
                                        <span className="text-xs text-slate-500">
                                            Difference compared to sector benchmark
                                        </span>
                                    </div>
                                )}
                                {data.historicalSizeDifference && (
                                    <div className="flex flex-col gap-2">
                                        <div
                                            className={
                                                "flex flex-row items-center gap-2 rounded-xl w-max py-1 px-1.5 bg-slate-100 border border-slate-400 text-slate-500"
                                            }
                                        >
                                            <span className="font-normal text-xs">{data.historicalSizeDifference}</span>
                                        </div>
                                        <span className="text-xs text-slate-500">
                                            Difference compared to {previousSurveyLabel}
                                        </span>
                                    </div>
                                )}
                                <div className={"text-sm col-span-full mt-2 print:hidden"}>
                                    <a
                                        className={"link"}
                                        rel="noopener noreferrer"
                                        href="/report-context#effectSizes"
                                        target="_blank"
                                    >
                                        Learn about effect sizes
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    );
}
