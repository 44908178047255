import { useContext, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Tippy from "@tippyjs/react";
import { DocumentCheckIcon } from "@heroicons/react/24/outline";
import { SparklesIcon, CursorArrowRaysIcon } from "@heroicons/react/24/solid";

import {
    BAR_CHART_ONLY_SECTOR_BENCHMARK_COLOUR_PALETTE,
    BAR_CHART_STANDARD_COLOUR_PALETTE_WITH_LIGHTS,
    BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE,
    BASIC_ANIMATION_VARIANTS,
} from "../../../../../../utils/constants";
import { Context } from "../../../../../../context/Context";

import Bar from "./Bar";
import FancyCheckBox from "../../../../../SharedComponents/Form/FancyCheckBox";
import Alert, { ALERT_TYPE } from "../../../../../SharedComponents/Alert";
import SausageLegendItem from "./CustomLegends/SausageLegendItem";
import ScaleAxis from "./ScaleAxis";
import BarInfoBubble from "./BarInfoBubble";
import FatDropdown from "../../../../../SharedComponents/FatDropdown";
import LowMediumHighBubble from "../../SharedChartComponents/LowMediumHighBubble";
import Tag from "../../../../../SharedComponents/Tag";

export default function BarChart(props) {
    const {
        title,
        subtitle,
        data,
        questions,
        multiple,
        extraContent,
        trendBubble,
        colourPalette,
        testId,
        emphasiseSectorBenchmark,
        emphasiseOrganisationCategory,
        noItemTitle,
        demo,
        topXAxis,
        percentage,
        axisLabels,
        onlySectorBenchmarkToggle,
        infoBubble,
        noInfoBubbleOnPrint,
        noQuestionBubble,
        onlyBubbleOnFirstBarOnPrint,
        filterText,
        percentageAxisLabels,
        noBottomAxis,
        noSelectableCategories,
        labelAddOn,
        percentageAxisMargin,
        noTrendLegend,
        alwaysDisplayFilterDropdown,
        customTopAxis,
        customBottomAxis,
        showResponses,
        lowMediumHighBubble,
        questionsTitleTextColour,
        barEmptyBackgroundColour,
        barTransitionDuration,
        infoBubblePlacement,
        noInfoBubbles,
        noFlexAtTopOnPrint,
        singleNoteworthyTrend,
        hidePercentScoreNumbers,
        compactMode,
    } = props;

    const [categories, setCategories] = useState([]);
    const [currentColourPalette, setCurrentColourPalette] = useState(BAR_CHART_STANDARD_COLOUR_PALETTE_WITH_LIGHTS);
    const [chosenCategories, setChosenCategories] = useState([]);
    const [currentData, setCurrentData] = useState([]);
    const [currentSurveyLabel, setCurrentSurveyLabel] = useState("Current survey");
    const [previousSurveyLabel, setPreviousSurveyLabel] = useState("Previous survey");

    const context = useContext(Context);

    useEffect(() => {
        if (context.aceSurveyReportData) {
            setCurrentSurveyLabel(context.aceSurveyReportData.currentSurveyLabel);
            setPreviousSurveyLabel(context.aceSurveyReportData.previousSurveyLabel);
        }

        if (colourPalette) {
            setCurrentColourPalette(colourPalette);
        }
        // Set category toggles
        if (data) {
            const tempData = JSON.parse(JSON.stringify(data));

            let colourPaletteCounter = 0;

            // Set background and text colour for each Bar
            tempData.forEach((barChartItem, index) => {
                if (emphasiseOrganisationCategory) {
                    if (barChartItem.category === currentSurveyLabel) {
                        barChartItem.name = currentSurveyLabel;
                    }
                }

                if (questions || multiple) {
                    barChartItem.data.forEach((barChartItemItem, index) => {
                        if (barChartItemItem.name.includes("Sector benchmark") && emphasiseSectorBenchmark) {
                            barChartItemItem.backgroundColour =
                                BAR_CHART_ONLY_SECTOR_BENCHMARK_COLOUR_PALETTE[0].backgroundColour;
                            barChartItemItem.textColour = BAR_CHART_ONLY_SECTOR_BENCHMARK_COLOUR_PALETTE[0].textColour;
                        } else if (
                            barChartItemItem.name === (testId ? "Test bar chart category" : currentSurveyLabel)
                        ) {
                            barChartItemItem.backgroundColour =
                                BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE[0].backgroundColour;
                            barChartItemItem.textColour = BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE[0].textColour;
                        } else {
                            barChartItemItem.backgroundColour =
                                currentColourPalette[colourPaletteCounter].backgroundColour;
                            barChartItemItem.textColour = currentColourPalette[colourPaletteCounter].textColour;

                            processPaletteCounter();
                        }

                        if (questions && barChartItem.data.length === index + 1) {
                            colourPaletteCounter = 0;
                        }
                    });
                } else {
                    if (barChartItem.name.includes("Sector benchmark") && emphasiseSectorBenchmark) {
                        barChartItem.backgroundColour =
                            BAR_CHART_ONLY_SECTOR_BENCHMARK_COLOUR_PALETTE[0].backgroundColour;
                        barChartItem.textColour = BAR_CHART_ONLY_SECTOR_BENCHMARK_COLOUR_PALETTE[0].textColour;
                    } else if (barChartItem.name === (testId ? "Test bar chart category" : currentSurveyLabel)) {
                        barChartItem.backgroundColour = BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE[0].backgroundColour;
                        barChartItem.textColour = BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE[0].textColour;
                    } else {
                        barChartItem.backgroundColour = currentColourPalette[colourPaletteCounter].backgroundColour;
                        barChartItem.textColour = currentColourPalette[colourPaletteCounter].textColour;

                        processPaletteCounter();
                    }

                    if (data.length === index + 1) {
                        colourPaletteCounter = 0;
                    }
                }
            });

            setCurrentData(tempData);

            function processPaletteCounter() {
                if (colourPaletteCounter === currentColourPalette.length - 1) {
                    colourPaletteCounter = 0;
                } else {
                    colourPaletteCounter = colourPaletteCounter + 1;
                }
            }

            // Set category toggle data
            const tempCategories = [];

            if (questions || multiple) {
                tempData.forEach((barChartItem) => {
                    barChartItem.data.forEach((barChartItemData) => {
                        pushCategoryDataIntoTempCategoriesArray(barChartItemData);
                    });
                });
            } else {
                tempData.forEach((barChartItem) => {
                    pushCategoryDataIntoTempCategoriesArray(barChartItem);
                });
            }

            function pushCategoryDataIntoTempCategoriesArray(barChartItem) {
                if (!tempCategories.some((category) => category.name === barChartItem.category)) {
                    tempCategories.push({
                        name: barChartItem.category,
                        colour:
                            barChartItem.category === "Sector benchmark"
                                ? title === "Satisfaction on six main dimensions"
                                    ? "amber"
                                    : "amber"
                                : barChartItem.category === (testId ? "Test bar chart category" : currentSurveyLabel)
                                ? title === "Satisfaction on six main dimensions"
                                    ? "blue"
                                    : "blue"
                                : barChartItem.textColour
                                ? barChartItem.textColour.replace(/text-|-950/g, "")
                                : "primary",
                    });
                }
            }

            setCategories(tempCategories);
            setChosenCategories(tempCategories);
        }
    }, [
        title,
        colourPalette,
        currentColourPalette,
        data,
        demo,
        emphasiseSectorBenchmark,
        multiple,
        questions,
        testId,
        context,
        emphasiseOrganisationCategory,
        currentSurveyLabel,
    ]);

    function processChosenCategories(category) {
        if (chosenCategories.some((chosenCategory) => chosenCategory.name === category.name)) {
            const currentCategories = chosenCategories.filter(
                (currentCategory) => category.name !== currentCategory.name
            );
            setChosenCategories(currentCategories);
        } else {
            setChosenCategories((oldChosenCategories) => [...oldChosenCategories, category]);
        }
    }

    return (
        <div
            className={"flex flex-col gap-5 print:gap-4 " + (noFlexAtTopOnPrint ? "print:space-y-4 print:block" : "")}
            data-testid={testId && testId}
        >
            {(title || trendBubble || subtitle) && (
                <div className="flex flex-col gap-3">
                    <div className="flex flex-row justify-between items-center gap-2">
                        <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight capitalize">
                            {title}
                        </h2>
                        {trendBubble}
                        {showResponses && !questions && !multiple && (
                            <div
                                className={
                                    "flex flex-row items-center gap-2 py-1.5 px-2 bg-secondary-100 border " +
                                    "border-secondary-400 rounded-xl w-fit font-normal shrink-0 h-max"
                                }
                            >
                                <DocumentCheckIcon className={"h-4 w-4 text-secondary-600 stroke-current stroke-2"} />
                                <span className="text-sm text-secondary-600">
                                    <span className="font-medium">{data[0].responseCount}</span> total responses
                                </span>
                            </div>
                        )}
                    </div>
                    {subtitle && <p className="text-sm text-slate-500">{subtitle}</p>}
                </div>
            )}
            {extraContent && extraContent}
            {!noTrendLegend && (
                <div className={"flex flex-col gap-4 justify-between"}>
                    {!noTrendLegend && (
                        <div className={"flex flex-row gap-2 w-max"}>
                            <SausageLegendItem label="Negative trend" backgroundColour="bg-red-600" horizontal large />
                            <SausageLegendItem
                                label={
                                    "Positive trend" +
                                    (previousSurveyLabel === ""
                                        ? ""
                                        : " (based on " +
                                          (context.aceSurveyReportData
                                              ? previousSurveyLabel
                                              : "test-previous-survey-label") +
                                          ")")
                                }
                                backgroundColour="bg-green-600"
                                horizontal
                                large
                            />
                        </div>
                    )}
                    {infoBubble && !noInfoBubbles && (
                        <div
                            className={
                                "flex flex-row gap-2 p-2 rounded-xl bg-blue-50 border border-blue-200 shadow-sm " +
                                "text-blue-600 print:hidden w-fit"
                            }
                        >
                            <CursorArrowRaysIcon className={"shrink-0 block h-5 w-5 transition-colors"} />
                            <span className={"font-title text-sm"}>
                                Hover over chart bars to see more information. i.e benchmark rating and effect sizes
                            </span>
                        </div>
                    )}
                </div>
            )}
            {categories.length >= 2 &&
                !noSelectableCategories &&
                (categories.length <= 8 && !alwaysDisplayFilterDropdown ? (
                    <div
                        className={
                            "flex flex-row gap-2 text-sm font-title text-primary-800 items-center justify-start " +
                            "overflow-x-scroll pb-1 print:hidden"
                        }
                    >
                        {categories.map((category, index) =>
                            onlySectorBenchmarkToggle ? (
                                category.name === "Sector benchmark" && (
                                    <FancyCheckBox
                                        testId={category.name.replace(/\s+/g, "-").toLowerCase() + "-toggle-test-id"}
                                        key={index}
                                        labelText={category.name}
                                        value={chosenCategories.some(
                                            (chosenCategory) => chosenCategory.name === category.name
                                        )}
                                        name={category.name}
                                        colour={category.colour}
                                        setValue={() => processChosenCategories(category)}
                                    />
                                )
                            ) : (
                                <FancyCheckBox
                                    testId={category.name.replace(/\s+/g, "-").toLowerCase() + "-toggle-test-id"}
                                    key={index}
                                    labelText={category.name}
                                    value={chosenCategories.some(
                                        (chosenCategory) => chosenCategory.name === category.name
                                    )}
                                    name={category.name}
                                    colour={category.colour}
                                    setValue={() => processChosenCategories(category)}
                                />
                            )
                        )}
                    </div>
                ) : (
                    <div className={"flex justify-start print:hidden"}>
                        <FatDropdown
                            text={filterText}
                            content={
                                <div
                                    className={
                                        "flex flex-col text-sm font-title text-primary-800 justify-start " +
                                        "overflow-y-scroll print:hidden bg-white shadow-lg rounded-xl " +
                                        "border-slate-100 border h-64 w-max"
                                    }
                                >
                                    {categories.map((category, index) =>
                                        onlySectorBenchmarkToggle ? (
                                            category.name === "Sector benchmark" && (
                                                <FancyCheckBox
                                                    testId={
                                                        category.name.replace(/\s+/g, "-").toLowerCase() +
                                                        "-toggle-test-id"
                                                    }
                                                    key={index}
                                                    labelText={category.name}
                                                    value={chosenCategories.some(
                                                        (chosenCategory) => chosenCategory.name === category.name
                                                    )}
                                                    name={category.name}
                                                    colour={category.colour}
                                                    setValue={() => processChosenCategories(category)}
                                                    minimal
                                                />
                                            )
                                        ) : (
                                            <FancyCheckBox
                                                testId={
                                                    category.name.replace(/\s+/g, "-").toLowerCase() + "-toggle-test-id"
                                                }
                                                key={index}
                                                labelText={category.name}
                                                value={chosenCategories.some(
                                                    (chosenCategory) => chosenCategory.name === category.name
                                                )}
                                                name={category.name}
                                                colour={category.colour}
                                                setValue={() => processChosenCategories(category)}
                                                minimal
                                            />
                                        )
                                    )}
                                </div>
                            }
                        />
                    </div>
                ))}
            {currentData.length !== 0 ? (
                <div className="flex flex-col gap-2 ">
                    {customTopAxis && customTopAxis}
                    {topXAxis && (
                        <ScaleAxis
                            top
                            percentage={percentage}
                            labels={axisLabels}
                            percentageLabels={percentageAxisLabels}
                            percentageAxisMargin={percentageAxisMargin}
                        />
                    )}
                    {questions || multiple ? (
                        <div
                            className={
                                "flex flex-col font-title font-semibold transition-all " +
                                (noInfoBubbles ? "print:gap-0" : compactMode ? "print:gap-0" : "print:gap-6")
                            }
                        >
                            {currentData.map((barChartData, index) => (
                                // TODO: Placement of tooltip must be responsive
                                <Tippy
                                    content={<BarInfoBubble data={barChartData} />}
                                    className={"group"}
                                    arrow={false}
                                    animation={"shift-away-subtle"}
                                    disabled={!infoBubble || questions || noInfoBubbles}
                                    interactive={true}
                                    placement={infoBubblePlacement ? infoBubblePlacement : "left"}
                                    key={index}
                                >
                                    <div
                                        className={
                                            "gap-1 font-title font-semibold rounded-xl transition-all " +
                                            "break-inside-avoid-page " +
                                            (questions ? "my-2 " : "my-0.5 ") +
                                            (compactMode ? "grid grid-cols-5 items-center " : "flex flex-col ") +
                                            (infoBubble &&
                                                !questions &&
                                                " hover:bg-slate-200 hover:px-1.5 hover:py-1 hover:shadow-sm " +
                                                    "cursor-pointer ")
                                        }
                                        key={index}
                                    >
                                        {barChartData.itemTitle && !noItemTitle && (
                                            <div
                                                className={
                                                    "text-sm font-medium " +
                                                    (compactMode ? "col-span-1  " : "") +
                                                    (questionsTitleTextColour
                                                        ? questionsTitleTextColour
                                                        : "text-primary-600")
                                                }
                                            >
                                                {compactMode ? (
                                                    <div className={"flex flex-col gap-1"}>
                                                        {barChartData.itemTitle
                                                            .split(/(\([^)]+\))/)
                                                            .map((item) => item.trim())
                                                            .filter((item) => item !== "")
                                                            .map((item, index) => (
                                                                <span
                                                                    className={
                                                                        index === 1 ? "text-xs text-slate-500" : ""
                                                                    }
                                                                    key={index}
                                                                >
                                                                    {item}
                                                                </span>
                                                            ))}
                                                    </div>
                                                ) : (
                                                    barChartData.itemTitle
                                                )}
                                            </div>
                                        )}
                                        <div
                                            className={
                                                "flex flex-col gap-3 font-title font-semibold transition-all " +
                                                "break-inside-avoid-page print:block print:space-y-3 " +
                                                (compactMode ? "col-span-4  " : "") +
                                                (questions
                                                    ? compactMode
                                                        ? "my-0 print:my-0"
                                                        : "my-2 print:my-0"
                                                    : "my-0")
                                            }
                                            key={index}
                                        >
                                            {questions && !noQuestionBubble && barChartData.itemText && (
                                                <div className={"flex justify-between gap-3"}>
                                                    <div
                                                        className={
                                                            "text-sm text-secondary-600 font-medium bg-secondary-50 " +
                                                            "border border-secondary-100 rounded-xl w-fit p-2"
                                                        }
                                                    >
                                                        {barChartData.itemText}
                                                    </div>
                                                    {showResponses && (
                                                        <div
                                                            className={
                                                                "flex flex-row items-center gap-2 py-1.5 px-2 bg-secondary-100 border " +
                                                                "border-secondary-400 rounded-xl w-fit font-normal shrink-0"
                                                            }
                                                        >
                                                            <DocumentCheckIcon
                                                                className={
                                                                    "h-4 w-4 text-secondary-600 stroke-current stroke-2"
                                                                }
                                                            />
                                                            <span className="text-sm text-secondary-600">
                                                                <span className="font-medium">
                                                                    {barChartData.responseCount}
                                                                </span>{" "}
                                                                total responses
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            <div className="flex flex-col font-title font-semibold transition-all">
                                                <div
                                                    className={
                                                        "print:flex print:flex-col " +
                                                        (barChartData.data.length > 2
                                                            ? onlyBubbleOnFirstBarOnPrint ||
                                                              noInfoBubbleOnPrint ||
                                                              noInfoBubbles
                                                                ? " print:gap-0"
                                                                : " print:gap-4"
                                                            : "print:gap-0")
                                                    }
                                                >
                                                    {barChartData.data.map((barChartDataItem, index) => (
                                                        <Tippy
                                                            content={
                                                                <BarInfoBubble
                                                                    data={barChartDataItem}
                                                                    parentData={barChartData}
                                                                    singleBar
                                                                />
                                                            }
                                                            className={"group"}
                                                            arrow={false}
                                                            animation={"shift-away-subtle"}
                                                            disabled={!infoBubble || multiple || noInfoBubbles}
                                                            interactive={true}
                                                            placement={
                                                                infoBubblePlacement ? infoBubblePlacement : "left"
                                                            }
                                                            key={index}
                                                        >
                                                            <div
                                                                className={
                                                                    "flex flex-col gap-1 font-title font-semibold " +
                                                                    "rounded-xl transition-all break-inside-avoid-page " +
                                                                    (infoBubble &&
                                                                        !multiple &&
                                                                        " hover:bg-slate-200 hover:px-1 hover:py-0.5 " +
                                                                            "hover:shadow-sm cursor-pointer ")
                                                                }
                                                            >
                                                                {onlyBubbleOnFirstBarOnPrint &&
                                                                    index === 0 &&
                                                                    !noInfoBubbleOnPrint &&
                                                                    !noInfoBubbles && (
                                                                        <BarInfoBubble
                                                                            data={barChartDataItem}
                                                                            parentData={barChartData}
                                                                            singleBar
                                                                            showWhenPrinting
                                                                        />
                                                                    )}
                                                                {!onlyBubbleOnFirstBarOnPrint &&
                                                                    !noInfoBubbleOnPrint &&
                                                                    !noInfoBubbles && (
                                                                        <BarInfoBubble
                                                                            data={barChartDataItem}
                                                                            parentData={barChartData}
                                                                            singleBar
                                                                            showWhenPrinting
                                                                        />
                                                                    )}
                                                                <AnimatePresence key={index}>
                                                                    {chosenCategories.some(
                                                                        (category) =>
                                                                            category.name === barChartDataItem.category
                                                                    ) && (
                                                                        <motion.div
                                                                            initial={"inactiveHeight"}
                                                                            animate={"activeHeight"}
                                                                            exit={"inactiveHeight"}
                                                                            transition={{ duration: 0.6 }}
                                                                            variants={BASIC_ANIMATION_VARIANTS}
                                                                        >
                                                                            <Bar
                                                                                label={
                                                                                    barChartDataItem.name &&
                                                                                    barChartDataItem.name
                                                                                }
                                                                                score={
                                                                                    barChartDataItem.percentScore &&
                                                                                    barChartDataItem.percentScore
                                                                                }
                                                                                historicalScore={
                                                                                    barChartDataItem.historicalScore &&
                                                                                    barChartDataItem.historicalScore
                                                                                }
                                                                                backgroundColour={
                                                                                    barChartDataItem.backgroundColour
                                                                                }
                                                                                textColour={barChartDataItem.textColour}
                                                                                separationGap={"my-0.5"}
                                                                                barEmptyBackgroundColour={
                                                                                    barEmptyBackgroundColour &&
                                                                                    barEmptyBackgroundColour
                                                                                }
                                                                                transitionDuration={
                                                                                    barTransitionDuration
                                                                                }
                                                                                hidePercentScoreNumber={
                                                                                    hidePercentScoreNumbers
                                                                                }
                                                                                smallBar={compactMode}
                                                                            />
                                                                        </motion.div>
                                                                    )}
                                                                </AnimatePresence>
                                                            </div>
                                                        </Tippy>
                                                    ))}
                                                </div>
                                            </div>
                                            {barChartData.noteworthyContent && (
                                                <div
                                                    className={
                                                        "flex flex-row gap-2 p-2 font-normal rounded-xl bg-green-50 " +
                                                        "border border-green-200 shadow-sm " +
                                                        "text-green-600 w-fit"
                                                    }
                                                >
                                                    <SparklesIcon
                                                        className={"shrink-0 block h-5 w-5 transition-colors"}
                                                    />
                                                    <span className={"font-title text-sm"}>
                                                        <span className="font-medium">Noteworthy trend: </span>
                                                        {barChartData.noteworthyContent}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Tippy>
                            ))}
                        </div>
                    ) : (
                        <div
                            className={
                                "flex flex-col gap-0 font-title font-semibold transition-all break-inside-avoid-page " +
                                (currentData.length > 2
                                    ? onlyBubbleOnFirstBarOnPrint || noInfoBubbleOnPrint || noInfoBubbles
                                        ? " print:gap-0"
                                        : " print:gap-4"
                                    : "print:gap-0")
                            }
                        >
                            {currentData.map((barChartDataItem, index) => (
                                <div
                                    className={"print:flex print:flex-col print:gap-1 break-inside-avoid-page"}
                                    key={index}
                                >
                                    {onlyBubbleOnFirstBarOnPrint &&
                                        index === 0 &&
                                        !noInfoBubbleOnPrint &&
                                        !noInfoBubbles && (
                                            <BarInfoBubble
                                                showWhenPrinting
                                                data={barChartDataItem}
                                                parentData={currentData}
                                                singleBar
                                            />
                                        )}
                                    {!onlyBubbleOnFirstBarOnPrint && !noInfoBubbleOnPrint && !noInfoBubbles && (
                                        <BarInfoBubble
                                            showWhenPrinting
                                            data={barChartDataItem}
                                            parentData={currentData}
                                            singleBar
                                        />
                                    )}
                                    <Tippy
                                        content={
                                            lowMediumHighBubble ? (
                                                <div className="flex-col gap-4 space-y-2 rounded-xl p-3.5 border border-slate-200 font-normal text-xs text-slate-500 bg-white shadow-lg">
                                                    <div className="text-sm font-medium flex flex-row gap-3 justify-between items-center text-secondary-600">
                                                        {barChartDataItem.name}
                                                        <Tag
                                                            status={
                                                                barChartDataItem.percentScore &&
                                                                (barChartDataItem.percentScore > 0 &&
                                                                barChartDataItem.percentScore < 25
                                                                    ? "Entry"
                                                                    : barChartDataItem.percentScore >= 25 &&
                                                                      barChartDataItem.percentScore < 50
                                                                    ? "Foundation"
                                                                    : barChartDataItem.percentScore >= 50 &&
                                                                      barChartDataItem.percentScore < 75
                                                                    ? "Advanced"
                                                                    : "Mastery")
                                                            }
                                                            backgroundColour={"bg-secondary-100"}
                                                            textColour={"text-secondary-600"}
                                                        />
                                                    </div>
                                                    <div className="text-sm font-medium text-primary-600">
                                                        <div
                                                            className={
                                                                "flex flex-col gap-2 border border-y-0 border-r-0 border-l-2 pl-2 h-max border-secondary-400 "
                                                            }
                                                        >
                                                            <LowMediumHighBubble
                                                                percentage={barChartDataItem.percentScore}
                                                                small
                                                                customStyling={
                                                                    "bg-secondary-100 text-secondary-600 border-secondary-600"
                                                                }
                                                            />
                                                            <span className="text-xs text-slate-500 font-normal">
                                                                Percentage score (%)
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <p className="text-xs font-medium text-slate-500">
                                                        <span className="font-normal">Strength: </span>
                                                        {barChartDataItem.description}
                                                    </p>
                                                </div>
                                            ) : (
                                                <BarInfoBubble
                                                    data={barChartDataItem}
                                                    parentData={currentData}
                                                    singleBar
                                                />
                                            )
                                        }
                                        className={"group"}
                                        arrow={false}
                                        animation={"shift-away-subtle"}
                                        disabled={!infoBubble || questions || noInfoBubbles}
                                        interactive={true}
                                        placement={infoBubblePlacement ? infoBubblePlacement : "left"}
                                    >
                                        <span
                                            className={
                                                "flex flex-col gap-1 font-title font-semibold rounded-xl " +
                                                "transition-all break-inside-avoid-page " +
                                                (infoBubble &&
                                                    " hover:bg-slate-200 hover:px-1 hover:py-0.5 hover:shadow-sm " +
                                                        "cursor-pointer ")
                                            }
                                        >
                                            <AnimatePresence key={index}>
                                                {chosenCategories.some(
                                                    (category) => category.name === barChartDataItem.category
                                                ) && (
                                                    <motion.div
                                                        initial={"inactiveHeight"}
                                                        animate={"activeHeight"}
                                                        exit={"inactiveHeight"}
                                                        transition={{ duration: 0.6 }}
                                                        variants={BASIC_ANIMATION_VARIANTS}
                                                    >
                                                        <Bar
                                                            labelAddOn={labelAddOn}
                                                            label={barChartDataItem.name}
                                                            score={
                                                                barChartDataItem.percentScore &&
                                                                barChartDataItem.percentScore
                                                            }
                                                            historicalScore={
                                                                barChartDataItem.historicalScore &&
                                                                barChartDataItem.historicalScore
                                                            }
                                                            backgroundColour={barChartDataItem.backgroundColour}
                                                            textColour={barChartDataItem.textColour}
                                                            separationGap={"my-1"}
                                                            noPercentage={percentage}
                                                            barEmptyBackgroundColour={
                                                                barEmptyBackgroundColour && barEmptyBackgroundColour
                                                            }
                                                            transitionDuration={barTransitionDuration}
                                                            hidePercentScoreNumber={hidePercentScoreNumbers}
                                                        />
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>
                                        </span>
                                    </Tippy>
                                </div>
                            ))}
                            {singleNoteworthyTrend && (
                                <div
                                    className={
                                        "flex flex-row gap-2 p-2 font-normal rounded-xl bg-green-50 " +
                                        "border border-green-200 shadow-sm my-2 " +
                                        "text-green-600 w-fit "
                                    }
                                >
                                    <SparklesIcon className={"shrink-0 block h-5 w-5 transition-colors"} />
                                    <span className={"font-title text-sm"}>
                                        <span className="font-medium">Noteworthy trend: </span>
                                        {singleNoteworthyTrend}
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                    {!noBottomAxis && (
                        <ScaleAxis
                            percentage={percentage}
                            labels={axisLabels}
                            percentageLabels={percentageAxisLabels}
                            percentageAxisMargin={percentageAxisMargin}
                        />
                    )}
                    {customBottomAxis && customBottomAxis}
                </div>
            ) : (
                <Alert text={"Data set for this chart is empty."} type={ALERT_TYPE.WARNING} active noMargins />
            )}
        </div>
    );
}
