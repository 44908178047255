import { useState } from "react";
import { ResponsiveLine } from "@nivo/line";

import { LINE_CHART_STANDARD_COLOUR_PALETTE_SOLID } from "../../../../../../../utils/constants";

import SausageLegendItem from "../../../Charts/CustomCharts/CustomLegends/SausageLegendItem";
import LowMediumHighBubble from "../../../SharedChartComponents/LowMediumHighBubble";

export default function LineChartCard(props) {
    const { data, title, extraStyling, noContainer, printTitle } = props;

    const [hiddenLines, setHiddenLines] = useState([]);

    const toggleLineVisibility = (lineId) => {
        if (hiddenLines.includes(lineId)) {
            setHiddenLines(hiddenLines.filter((id) => id !== lineId));
        } else {
            setHiddenLines([...hiddenLines, lineId]);
        }
    };

    return (
        <div
            className={
                "flex flex-col gap-6 print:gap-8 w-full print:p-0 print:border-0 print:shadow-none break-before-page " +
                (noContainer ? "p-0 " : "bg-white p-4 rounded-xl border border-slate-100 shadow-sm ") +
                extraStyling
            }
        >
            {printTitle && (
                <h2 className="text-lg text-secondary-600 font-medium font-title tracking-tight capitalize">
                    {printTitle}
                </h2>
            )}
            <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight capitalize">{title}</h2>
            <div className={"flex flex-row items-center gap-2 px-2 py-1 rounded-xl w-fit"}>
                <div className={"flex flex-row items-center gap-4"}>
                    {data.map((dataItem, index) => (
                        <SausageLegendItem
                            key={index + "legend"}
                            label={dataItem.id}
                            backgroundColour={LINE_CHART_STANDARD_COLOUR_PALETTE_SOLID[index].backgroundColour}
                            textColour={LINE_CHART_STANDARD_COLOUR_PALETTE_SOLID[index].textColour}
                            large
                            line
                            onClick={() => toggleLineVisibility(dataItem.id)}
                            clickable
                            chosen={!hiddenLines.some((legend) => legend === dataItem.id)}
                            isChoosable
                        />
                    ))}
                </div>
            </div>
            <div className="w-full h-[500px]">
                <ResponsiveLine
                    data={data.filter((line) => !hiddenLines.includes(line.id))}
                    colors={(line) => line.color}
                    margin={{ top: 8, right: 32, bottom: 50, left: 70 }}
                    xScale={{ type: "point" }}
                    yScale={{
                        type: "linear",
                        min: "auto",
                        max: "auto",
                    }}
                    yFormat={(value) => `${value * 100}%`} // Format Y-axis ticks to show percentage
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "Year",
                        legendOffset: 40,
                        legendPosition: "middle",
                        truncateTickAt: 0,
                        legendTextStyle: {
                            fontFamily: "Inter",
                            fontSize: "15px",
                        },
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "Percent",
                        legendOffset: -60,
                        legendPosition: "middle",
                        truncateTickAt: 0,
                        legendTextStyle: {
                            fill: "#94a3b8",
                            fontFamily: "Inter",
                            fontWeight: "600",
                            fontSize: "15px",
                        },
                        format: (value) => `${Number(value)}%`,
                    }}
                    pointSize={5}
                    pointColor={{ theme: "background" }}
                    pointBorderWidth={5}
                    pointBorderColor={{ from: "serieColor" }}
                    pointLabelYOffset={-12}
                    lineWidth={3}
                    useMesh={true}
                    theme={{
                        axis: {
                            ticks: {
                                text: {
                                    fill: "#94a3b8",
                                    fontFamily: "Inter",
                                    fontWeight: "600",
                                    fontSize: "12px",
                                },
                                line: {
                                    stroke: "#cbd5e1",
                                    strokeWidth: 2,
                                },
                            },
                            legend: {
                                text: {
                                    fill: "#94a3b8",
                                    fontFamily: "Inter",
                                    fontWeight: "600",
                                    fontSize: "16px",
                                },
                            },
                        },
                        grid: {
                            line: {
                                stroke: "#cbd5e1",
                                strokeWidth: 2,
                                strokeDasharray: "4 4",
                            },
                        },
                        labels: {
                            fill: "#94a3b8",
                            fontFamily: "Inter",
                            fontWeight: "600",
                            fontSize: "12px",
                        },
                    }}
                    tooltip={(tooltipData) => {
                        return (
                            <div className="flex-col gap-4 space-y-2 rounded-xl p-3.5 border border-slate-200 font-normal text-xs bg-white shadow-lg">
                                <div
                                    className={
                                        "text-sm font-medium flex flex-row justify-between items-center text-[" +
                                        (tooltipData.point.serieColor + "]")
                                    }
                                >
                                    {tooltipData.point.serieId}
                                </div>
                                <div className="text-sm font-medium text-primary-600">
                                    <div
                                        className={
                                            "flex flex-col gap-2 border border-y-0 border-r-0 border-l-2 pl-2 h-max bg-white border-[" +
                                            tooltipData.point.serieColor +
                                            "]"
                                        }
                                    >
                                        <LowMediumHighBubble
                                            percentage={tooltipData.point.data.y}
                                            small
                                            customStyling={
                                                "text-[" +
                                                tooltipData.point.serieColor +
                                                "] border-[" +
                                                tooltipData.point.serieColor +
                                                "]"
                                            }
                                        />
                                        <span className="text-xs text-slate-500 font-normal">Percentage score (%)</span>
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                />
            </div>
        </div>
    );
}
