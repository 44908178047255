import { useContext, useEffect, useState } from "react";

import {
    BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE,
    COACH_PLATFORM_PERMISSIONS,
} from "../../../../../../../utils/constants";
import { Context } from "../../../../../../../context/Context";

import DashboardLayout from "../../../../../../SharedComponents/DashboardLayout";
import ACEMindIndex from "../ChartCards/ACEMindIndex";
import TabbedNavigationLayout from "../../../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import QualitativeItemSection from "./Qualitative/QualitativeItemSection";
import AchievementItemVerticalBarChart from "../ChartCards/AchievementItemVerticalBarChart";
import NPSScoreCard from "../ChartCards/NPSScoreCard";
import BouncingBalls from "../../../../../../SharedComponents/Loading/BouncingBalls";
import QuestionsDataChart from "../ChartCards/QuestionsDataChart";
import AceReportPicker from "./AceReportPicker";

export default function ACEKeyThemes(props) {
    return (
        <DashboardLayout
            title="ACE Key Themes"
            requiredPermissions={[COACH_PLATFORM_PERMISSIONS.VIEW_GROUP_REPORTS]}
            otherElements={<AceReportPicker small />}
        >
            <ACEKeyThemesElement props={props} />
        </DashboardLayout>
    );
}

export function ACEKeyThemesElement(props) {
    const { demo, displayOnlyContent, noOpenEndedQuestions } = props;

    const [tabIndex, setTabIndex] = useState(0);
    const [tabList, setTabList] = useState([]);
    const [tabContent, setTabContent] = useState([]);

    const context = useContext(Context);

    useEffect(() => {
        if (
            context.aceSurveyReportData.aceMindsetIndex.length !== 0 &&
            context.aceSurveyReportData.netPromoterScores !== 0
        ) {
            let updatedTabList = ["ACE Scores", "Net Promoter Scores"];
            let updatedTabContent = [];

            updatedTabContent = [
                {
                    content: (
                        <div className="col-span-4 flex flex-col gap-4">
                            <div className="flex flex-col gap-2">
                                <h2 className="text-lg text-secondary-600 font-medium font-title tracking-tight">
                                    ACE Mindset Index
                                </h2>
                                {!demo && (
                                    <p className="text-slate-500">
                                        The ACE Mindset Index is a number between 0 and 100 that measures the likelihood
                                        someone would: Advocate or recommend the opportunity to others. Commit or stay
                                        in the network despite inevitable ups and downs. Engage or actively involve
                                        themselves with brand initiatives. It could be described as a state of mind
                                        where members feel positive about their involvement. The specific sentiment
                                        scores in previous sections of this report have been shown to influence this
                                        measure.
                                    </p>
                                )}
                            </div>
                            <ACEMindIndex
                                title={context.aceSurveyReportData.aceMindsetIndex[0].name}
                                demo={demo}
                                data={context.aceSurveyReportData.aceMindsetIndex[0].data}
                                detailed
                                subtitle={
                                    "The ACE Mindset Index is a number between 0 and 100 that measures the likelihood " +
                                    "they would: Advocate or recommend the opportunity to others. Commit " +
                                    "or stay in the network despite inevitable ups and downs. Engage or actively involve " +
                                    "themselves with brand initiatives. It could be described as a state of mind where " +
                                    "they feel positive about their involvement. The specific " +
                                    "sentiment scores in previous sections of this report have been shown to influence this " +
                                    "measure."
                                }
                            />
                        </div>
                    ),
                },
                {
                    content: (
                        <div className="flex flex-col gap-6">
                            <div className="flex flex-col gap-4">
                                <h2 className="text-lg text-secondary-600 font-medium font-title tracking-tight">
                                    Net Promoter Scores
                                </h2>
                                {context.aceSurveyReportData.netPromoterScores.map(
                                    (netPromoterScore, index) =>
                                        netPromoterScore.name === "NPS Score" && (
                                            <NPSScoreCard data={netPromoterScore} key={index} />
                                        )
                                )}
                            </div>
                            <div className="flex flex-col gap-4">
                                {context.aceSurveyReportData.netPromoterScores
                                    .reverse()
                                    .map(
                                        (netPromoterScore, index) =>
                                            netPromoterScore.items &&
                                            netPromoterScore.name !== "Text" &&
                                            netPromoterScore.name !== "Text" &&
                                            netPromoterScore.name !== "NPS Score" &&
                                            netPromoterScore.items
                                                .reverse()
                                                .map(
                                                    (netPromoterScoreItem) =>
                                                        netPromoterScoreItem.itemName !== "EXIT_MCQ" && (
                                                            <AchievementItemVerticalBarChart
                                                                key={index}
                                                                data={netPromoterScoreItem}
                                                                title={netPromoterScoreItem.itemTitle}
                                                                noTrendBubble
                                                                noBenchmarkAgree
                                                                sevenPointScale
                                                                ratingDivision={
                                                                    netPromoterScoreItem.itemTitle === "Recommend"
                                                                }
                                                                flippedPercentageScore={
                                                                    netPromoterScoreItem.itemTitle === "Exit"
                                                                }
                                                            />
                                                        )
                                                )
                                    )}
                                {context.aceSurveyReportData.netPromoterScores.map(
                                    (netPromoterScore) =>
                                        netPromoterScore.items &&
                                        netPromoterScore.name !== "Text" &&
                                        netPromoterScore.name !== "NPS Score" &&
                                        netPromoterScore.items.map(
                                            (netPromoterScoreItem) =>
                                                netPromoterScoreItem.itemName === "EXIT_MCQ" && (
                                                    <QuestionsDataChart
                                                        data={Object.keys(netPromoterScoreItem.score).map((key) => ({
                                                            itemTitle: netPromoterScoreItem.itemText,
                                                            itemText: key,
                                                            data: [
                                                                {
                                                                    name: context.aceSurveyReportData
                                                                        .currentSurveyLabel,
                                                                    percentScore: netPromoterScoreItem.score[key],
                                                                    category:
                                                                        context.aceSurveyReportData.currentSurveyLabel,
                                                                },
                                                            ],
                                                        }))}
                                                        title={netPromoterScoreItem.itemText}
                                                        colourPalette={BAR_CHART_WITH_QUESTIONS_COLOUR_PALETTE}
                                                        noItemTitles
                                                        questions
                                                        infoBubble
                                                        noInfoBubbleOnPrint
                                                        noBottomAxis
                                                        noTrendLegend
                                                    />
                                                )
                                        )
                                )}
                            </div>
                        </div>
                    ),
                },
            ];

            context.aceSurveyReportData.netPromoterScores.forEach((netPromoterScoreItem) => {
                netPromoterScoreItem.name === "Text" &&
                    !noOpenEndedQuestions &&
                    updatedTabList.push("Open-ended questions") &&
                    updatedTabContent.push({
                        content: (
                            <div
                                className={
                                    "flex flex-col gap-4 w-full print:p-0 print:shadow-none print:bg-white " +
                                    "print:border-0"
                                }
                            >
                                <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight">
                                    Open-ended questions
                                </h2>
                                <div className="flex flex-col gap-4 print:gap-8">
                                    {netPromoterScoreItem.items.map((item, index) => (
                                        <QualitativeItemSection
                                            key={index + "NPSScoreItem"}
                                            data={item}
                                            title={"Open-ended question #" + (index + 1)}
                                        />
                                    ))}
                                </div>
                            </div>
                        ),
                        readonly: true,
                    });
            });

            setTabList(updatedTabList);
            setTabContent(updatedTabContent);
        }
    }, [context.aceSurveyReportData, demo, noOpenEndedQuestions]);

    return context.aceSurveyReportDataLoading ? (
        <div className={"col-span-full flex items-center justify-center p-8 w-full"}>
            <BouncingBalls />
        </div>
    ) : (
        <TabbedNavigationLayout
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            tabList={tabList}
            backgroundColour={"bg-white"}
            noContainer
            tabContent={tabContent}
            tabsRowOnly
            displayOnlyContent={displayOnlyContent}
            secondaryTabs
        />
    );
}
