import { useContext, useState } from "react";

import { Context } from "../../../../../../../../context/Context";
import { BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID } from "../../../../../../../../utils/constants";

import TabbedNavigationLayout from "../../../../../../../SharedComponents/TabbedNavigation/TabbedNavigationLayout";
import QuestionsDataChart from "../../ChartCards/QuestionsDataChart";
import AchievementItem from "../../ChartCards/AchievementItem";

export default function PerDimensionItemsRegional(props) {
    const { demo, displayOnlyContent, displayProportions, displayOverall, noInfoBubbles, data, noInfoBubbleOnPrint } =
        props;

    const [tabIndex, setTabIndex] = useState(0);

    const context = useContext(Context);

    let tabList = [];
    let tabContent = [];

    let currentPerDimensionItemsData;

    if (data) {
        currentPerDimensionItemsData = data;
    } else {
        currentPerDimensionItemsData = context.aceSurveyReportData.sixDimensions;
    }

    currentPerDimensionItemsData.map(
        (dimension) =>
            tabList.push(dimension.name) &&
            tabContent.push({
                title: dimension.name,
                content: (
                    <div className="print:break-before-page flex flex-col gap-8 print:gap-8">
                        <QuestionsDataChart
                            noInfoBubbleOnPrint={noInfoBubbleOnPrint}
                            noInfoBubbles={noInfoBubbles}
                            questions
                            sixDimensions
                            data={dimension.items}
                            title={dimension.name + " Areas"}
                            demo={demo}
                            colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID}
                            topXAxis
                            noBottomAxis={displayOnlyContent}
                            infoBubble
                            noQuestionBubble
                            onlyBubbleOnFirstBarOnPrint
                            extraStyling={"break-inside-avoid-page"}
                            extraElements={
                                displayProportions && (
                                    <div
                                        className={
                                            "flex-col gap-4 print:gap-4 print:break-before-page print:-mt-8 " +
                                            (displayProportions ? "flex" : "hidden print:flex")
                                        }
                                    >
                                        <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight capitalize hidden print:block">
                                            {dimension.name + " response proportions"}
                                        </h2>
                                        {context.aceSurveyReportData.satisfactionProportions.map(
                                            (satisfactionProportion) =>
                                                satisfactionProportion.items.map(
                                                    (satisfactionProportionItem, index) =>
                                                        satisfactionProportionItem.itemDimension === dimension.name && (
                                                            <AchievementItem
                                                                key={
                                                                    index +
                                                                    "achievementItem" +
                                                                    satisfactionProportionItem.itemDimension
                                                                }
                                                                data={satisfactionProportionItem}
                                                                noLegend
                                                                noContainer
                                                                noTrendBubble={noInfoBubbles}
                                                                noInfoBubbles={noInfoBubbles}
                                                            />
                                                        )
                                                )
                                        )}
                                    </div>
                                )
                            }
                            extraElementsTop={
                                displayOverall && (
                                    <QuestionsDataChart
                                        noInfoBubbleOnPrint={noInfoBubbleOnPrint}
                                        noInfoBubbles={noInfoBubbles}
                                        sixDimensions
                                        data={
                                            dimension.regionalData
                                                ? dimension.data.concat(dimension.regionalData)
                                                : dimension.data
                                        }
                                        title={dimension.name}
                                        colourPalette={BAR_CHART_STANDARD_COLOUR_PALETTE_SOLID}
                                        emphasiseOrganisationCategory
                                        infoBubble
                                        onlyBubbleOnFirstBarOnPrint
                                        noContainer
                                        extraStyling={"print:-mt-8"}
                                    />
                                )
                            }
                        />
                    </div>
                ),
                readonly: true,
            })
    );

    return (
        <TabbedNavigationLayout
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            tabList={tabList}
            backgroundColour={"bg-white"}
            tabContent={tabContent}
            tabsRowOnly
            secondaryTabs
            gapLessTabsAndContent
            noContainer
            displayOnlyContent={displayOnlyContent}
            noTitle
        />
    );
}
