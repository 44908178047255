import { useEffect, useState } from "react";
import { TagCloud } from "react-tagcloud";
import { AnimatePresence, motion } from "framer-motion";
import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon, CloudIcon } from "@heroicons/react/24/solid";

import {
    BASIC_ANIMATION_VARIANTS,
    BUTTON_VARIANT_TYPES,
    COMMON_FUNCTION_WORDS,
} from "../../../../../../../../utils/constants";

import Button from "../../../../../../../SharedComponents/Button";
import ResponseCard from "./ResponseCard";
import PaginatedSearch from "../../../../../../../SharedComponents/Pagination/PaginatedSearch";
import Alert, { ALERT_TYPE } from "../../../../../../../SharedComponents/Alert";

export default function QualitativeItemSection(props) {
    const { data, title, firstItem, noPrintStyles, noContainer, onlyComments, onlyThemes } = props;

    const [wordCloudFrequencies, setWordCloudFrequencies] = useState([]);
    const [searchableResponses, setSearchableResponses] = useState([]);
    const [showWordCloud, setShowRecordings] = useState(false);

    const handleShowRecordings = () => {
        setShowRecordings((showWordCloud) => !showWordCloud);
    };

    function analyzeArray(strings) {
        const wordFrequencies = {};

        strings.forEach((string) => {
            const words = string.toLowerCase().split(/\W+/);

            words.forEach((word) => {
                if (word !== "" && !COMMON_FUNCTION_WORDS.includes(word)) {
                    if (wordFrequencies[word]) {
                        wordFrequencies[word]++;
                    } else {
                        wordFrequencies[word] = 1;
                    }
                }
            });
        });

        const frequencyArray = Object.keys(wordFrequencies).map((word) => ({
            value: word,
            count: wordFrequencies[word],
        }));

        frequencyArray.sort((a, b) => b.count - a.count);

        return frequencyArray.slice(0, 30);
    }

    useEffect(() => {
        if (data) {
            const analysisResult = analyzeArray(data.responseTexts);
            setWordCloudFrequencies(analysisResult);

            const tempSearchableResponses = [];

            data.responseTexts.forEach((responseText) =>
                tempSearchableResponses.push({
                    text: responseText,
                })
            );

            setSearchableResponses(tempSearchableResponses);
        }
    }, [data]);

    return (
        <div
            className={
                "flex flex-col w-full " +
                "print:p-0 print:shadow-none print:border-none gap-8 " +
                (noPrintStyles ? " " : firstItem ? " " : "break-before-page ") +
                (noContainer ? "p-0 " : "bg-white p-4 rounded-xl border border-slate-100 shadow-sm ")
            }
        >
            <div className="flex flex-col gap-8">
                <div className="flex flex-row items-center justify-between">
                    {title && (
                        <h2 className="text-lg text-primary-600 font-medium font-title tracking-tight">{title}</h2>
                    )}
                    <div className="flex flex-row gap-2">
                        {data.responseCount && (
                            <div
                                className={
                                    "flex flex-row items-center gap-2 py-1.5 px-2 bg-secondary-100 border " +
                                    "border-secondary-400 rounded-xl"
                                }
                            >
                                <ChatBubbleBottomCenterTextIcon
                                    className={"h-4 w-4 text-secondary-600 stroke-current stroke-2"}
                                />
                                <span className="text-sm text-secondary-600">
                                    <span className={"font-medium"}>{data.responseCount}</span> total responses
                                </span>
                            </div>
                        )}
                        {data.percentResponseCount && (
                            <div
                                className={
                                    "flex flex-row items-center gap-2 py-1.5 px-2 bg-secondary-100 border " +
                                    "border-secondary-400 rounded-xl"
                                }
                            >
                                <span className="text-sm text-secondary-600">
                                    <span className={"font-medium"}>{data.percentResponseCount}%</span> responded
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex flex-row justify-between items-center gap-4">
                    {data.itemText && (
                        <p className="text-base text-secondary-600 font-medium font-title tracking-tight">
                            "{data && data.itemText}"
                        </p>
                    )}
                    <Button
                        colour={BUTTON_VARIANT_TYPES.LIGHT_PRIMARY}
                        text={(showWordCloud ? "Hide" : "Show") + " word cloud"}
                        icon={<CloudIcon className="h-4 w-4 text-primary-600 stroke-current stroke-2 mr-2 " />}
                        secondIcon={
                            <ChevronRightIcon
                                className={
                                    "w-3.5 h-3.5 group-hover:text-primary-600 transition-transform transform " +
                                    "stroke-current stroke-2 ml-2 " +
                                    (showWordCloud ? "rotate-90" : "rotate-0")
                                }
                            />
                        }
                        iconPositionLeft
                        onClick={handleShowRecordings}
                        extraStyling="shrink-0 print:hidden"
                    />
                </div>
                <AnimatePresence>
                    {showWordCloud && (
                        <motion.div
                            initial={{ opacity: 0, height: "0px" }}
                            animate={{ opacity: 1, height: "157px" }}
                            exit={{ opacity: 0, height: "0px" }}
                            transition={{ duration: 0.4 }}
                            variants={BASIC_ANIMATION_VARIANTS}
                        >
                            <div
                                className={
                                    "font-title mt-8 print:mt-0 h-36 print:h-0 overflow-y-scroll p-3 bg-gradient-to-r from-emerald-50 " +
                                    "to-blue-50 rounded-xl"
                                }
                            >
                                <TagCloud
                                    minSize={16}
                                    maxSize={40}
                                    tags={wordCloudFrequencies}
                                    colorOptions={{ luminosity: "dark", hue: "random" }}
                                />
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
            <div className="flex flex-col gap-6">
                <div className={"flex flex-col gap-6 " + (onlyComments ? "hidden" : "")}>
                    {data.positiveThemes && data.positiveThemes.length !== 0 ? (
                        <div className="flex flex-col gap-4">
                            <h2 className="text-base text-green-600 font-medium font-title tracking-tight">
                                Positive themes
                            </h2>
                            <div className={"columns-2 gap-4"}>
                                {data.positiveThemes &&
                                    data.positiveThemes.map((theme, index) => (
                                        <div
                                            className="break-inside-avoid-column w-full flex flex-col gap-2 h-max mb-6"
                                            key={index}
                                        >
                                            <h2 className="text-sm text-green-600 font-medium font-title tracking-tight">
                                                {theme.title}:
                                            </h2>
                                            <div
                                                className={
                                                    "text-sm text-slate-500 bg-slate-50 border border-slate rounded-xl p-2"
                                                }
                                            >
                                                {theme.content}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    ) : (
                        <Alert
                            className={"hidden print:block"}
                            text={"No positive themes noted"}
                            type={ALERT_TYPE.WARNING}
                            active
                            noMargins
                        />
                    )}
                    {data.negativeThemes && data.negativeThemes.length !== 0 ? (
                        <div className="flex flex-col gap-4">
                            <h2 className="text-base text-red-600 font-medium font-title tracking-tight">
                                Negative themes
                            </h2>
                            <div className={"columns-2 gap-4"}>
                                {data.negativeThemes &&
                                    data.negativeThemes.map((theme, index) => (
                                        <div
                                            className="break-inside-avoid-column w-full flex flex-col gap-2 h-max mb-6"
                                            key={index}
                                        >
                                            <h2 className="text-sm text-red-600 font-medium font-title tracking-tight">
                                                {theme.title}:
                                            </h2>
                                            <div
                                                className={
                                                    "text-sm text-slate-500 bg-slate-50 border border-slate rounded-xl p-2"
                                                }
                                            >
                                                {theme.content}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    ) : (
                        <Alert
                            className={"hidden print:block"}
                            text={"No negative themes noted"}
                            type={ALERT_TYPE.WARNING}
                            active
                            noMargins
                        />
                    )}
                    {data.neutralThemes && data.neutralThemes.length !== 0 ? (
                        <div className="flex flex-col gap-4">
                            <h2 className="text-base text-slate-600 font-medium font-title tracking-tight">
                                Neutral themes
                            </h2>
                            <div className={"columns-2 gap-4"}>
                                {data.neutralThemes &&
                                    data.neutralThemes.map((theme, index) => (
                                        <div
                                            className="break-inside-avoid-column w-full flex flex-col gap-2 h-max mb-6"
                                            key={index}
                                        >
                                            <h2 className="text-sm text-slate-600 font-medium font-title tracking-tight">
                                                {theme.title}:
                                            </h2>
                                            <div
                                                className={
                                                    "text-sm text-slate-500 bg-slate-50 border border-slate rounded-xl p-2"
                                                }
                                            >
                                                {theme.content}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    ) : (
                        <Alert
                            className={"hidden print:block"}
                            text={"No neutral themes noted"}
                            type={ALERT_TYPE.WARNING}
                            active
                            noMargins
                        />
                    )}
                </div>
                <div className={"flex flex-col gap-3 " + (onlyThemes ? "hidden" : "")}>
                    <h2 className="text-base text-primary-600 font-medium font-title tracking-tight">Responses</h2>
                    <div className={"columns-2 gap-4 hidden print:block"}>
                        {searchableResponses.map((response, index) => (
                            <ResponseCard item={response} key={index} />
                        ))}
                    </div>
                    <div className={"block print:hidden"}>
                        <PaginatedSearch
                            items={searchableResponses}
                            itemName={"responses"}
                            ItemRow={ResponseCard}
                            cards
                            customItemsPerPage={9}
                            customCardGrid={searchableResponses.length === 0 ? "" : "columns-2 gap-4"}
                            inputBackgroundColour="bg-slate-100"
                            inputBorder="border-slate-200"
                            searchAttributes={["text"]}
                        />
                    </div>
                    {searchableResponses.length === 0 && (
                        <Alert
                            className={"print:block"}
                            text={"No responses to show"}
                            type={ALERT_TYPE.WARNING}
                            active
                            noMargins
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
