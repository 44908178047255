import { SIX_DIMENSIONS_ITEM_NAMES } from "./constants";

export function processAceSurveyReportData(rawAceSurveyScore, report, organisationName) {
    // Set labels
    if (rawAceSurveyScore.historicalScore) {
        const historicalScoreKeys = Object.keys(rawAceSurveyScore.historicalScore);
        const historicalYearLabel = historicalScoreKeys[historicalScoreKeys.length - 1];

        report.previousSurveyLabel =
            (rawAceSurveyScore.name ? rawAceSurveyScore.name : organisationName) + " " + historicalYearLabel;
        report.historicalYear = historicalYearLabel;
    }

    let currentSurveyDate = rawAceSurveyScore.surveyScore.reportDate.split("/");
    report.currentSurveyLabel =
        (rawAceSurveyScore.name ? rawAceSurveyScore.name : organisationName) + " " + currentSurveyDate[2];
    report.currentYear = currentSurveyDate[2];

    // Could need refactoring as it's injected as HTML
    if (rawAceSurveyScore.surveyScore.executiveSummary) {
        report.executiveSummary = rawAceSurveyScore.surveyScore.executiveSummary;
    }

    rawAceSurveyScore.surveyScore.reportFields.forEach((reportField) => {
        standardiseChildrenLevelNaming(reportField);
    });

    if (rawAceSurveyScore.historicalScore) {
        Object.values(rawAceSurveyScore.historicalScore).map((historicalItem) =>
            historicalItem.reportFields.forEach((reportField) => {
                standardiseChildrenLevelNaming(reportField);
            })
        );
    }

    if (rawAceSurveyScore.benchmarkScore) {
        rawAceSurveyScore.benchmarkScore.reportFields.forEach((reportField) => {
            standardiseChildrenLevelNaming(reportField);
        });
    }

    if (rawAceSurveyScore.historicalScore) {
        addHistoricalPercentScoresToLocalReportData(rawAceSurveyScore, report);
    }

    // Add reportFields to our local report data set (aceSurveyReportData)
    rawAceSurveyScore.surveyScore.reportFields.forEach((reportField) => {
        sortReportFieldForProcessing(reportField, false, false, report);
    });

    addPercentScoresToLocalReportData(report);

    if (rawAceSurveyScore.benchmarkScore) {
        // Add benchmark reportFields to our local report data set (aceSurveyReportData)
        rawAceSurveyScore.benchmarkScore.reportFields.forEach((reportField) => {
            sortReportFieldForProcessing(reportField, true, false, report);
        });
    }

    addRegionalData(report);
}

function standardiseChildrenLevelNaming(reportField) {
    // Standardise child-level naming: "itemAreaLevel" & "itemLevel" to "items"
    if (reportField.hasOwnProperty("itemLevel")) {
        reportField["items"] = reportField["itemLevel"];
        delete reportField["itemLevel"];
    } else if (reportField.hasOwnProperty("itemAreaLevel")) {
        reportField["items"] = reportField["itemAreaLevel"];
        delete reportField["itemAreaLevel"];
    } else if (reportField.hasOwnProperty("stateLevel")) {
        reportField["items"] = reportField["stateLevel"];
        delete reportField["stateLevel"];
    }
}

function addHistoricalPercentScoresToLocalReportData(rawAceSurveyData, report) {
    for (const [historicalScoreObjectName, historicalScore] of Object.entries(rawAceSurveyData.historicalScore)) {
        historicalScore.reportFields.forEach((historicalField) => {
            rawAceSurveyData.surveyScore.reportFields.forEach((currentField) => {
                // Parent level items
                if (
                    currentField.hasOwnProperty("percentScore") ||
                    currentField.hasOwnProperty("score") ||
                    currentField.hasOwnProperty("roleLevel")
                ) {
                    if (currentField.name === historicalField.name) {
                        if (currentField.hasOwnProperty("percentScore")) {
                            if (currentField.category === "Field Managers Level") {
                                if (currentField.fieldManagerCompetence === historicalField.fieldManagerCompetence) {
                                    currentField.historicalScore = historicalField.percentScore;
                                }
                            } else {
                                currentField.historicalScore = historicalField.percentScore;
                            }
                        } else if (currentField.hasOwnProperty("roleLevel")) {
                            currentField.historicalScore = historicalField.roleLevel;
                        } else {
                            currentField.historicalScore = historicalField.score;
                        }
                    }
                }

                // Children level items
                if (historicalField.name === currentField.name) {
                    if (currentField.hasOwnProperty("items") && historicalField.hasOwnProperty("items")) {
                        currentField.items.forEach((currentItem) => {
                            historicalField.items.forEach((historicalItem) => {
                                if (!currentItem.historicalScores) {
                                    currentItem.historicalScores = [];
                                }

                                if (
                                    !currentItem.historicalScores.some(
                                        (historicalScore) => historicalScore.name === historicalScoreObjectName
                                    ) &&
                                    currentItem.name === historicalItem.name
                                ) {
                                    if (historicalItem.percentScore) {
                                        currentItem.historicalScores.push({
                                            name: historicalScoreObjectName,
                                            percentScore: historicalItem.percentScore,
                                        });
                                    }
                                }

                                if (
                                    !currentItem.historicalScores.some(
                                        (historicalScore) => historicalScore.name === report.currentYear
                                    )
                                ) {
                                    if (currentItem.percentScore) {
                                        currentItem.historicalScores.push({
                                            name: report.currentYear,
                                            percentScore: currentItem.percentScore,
                                        });
                                    }
                                }

                                if (
                                    currentItem.hasOwnProperty("itemName") &&
                                    historicalItem.hasOwnProperty("itemName")
                                ) {
                                    if (currentItem.itemName === historicalItem.itemName) {
                                        if (currentField.name === "Each Response Proportions") {
                                            currentItem.historicalPercentScores = {
                                                overallDisagreePercentage: historicalItem.overallDisagreePercentage,
                                                overallAgreePercentage: historicalItem.overallAgreePercentage,
                                                stronglyAgreePercentage: historicalItem.stronglyAgreePercentage,
                                                agreePercentage: historicalItem.agreePercentage,
                                                disagreePercentage: historicalItem.disagreePercentage,
                                                stronglyDisagreePercentage: historicalItem.stronglyDisagreePercentage,
                                            };
                                        } else if (
                                            currentField.category === "NPS" ||
                                            currentField.name === "Follow Through"
                                        ) {
                                            currentItem.historicalPercentScores = {
                                                stronglyAgreePercentage: historicalItem.stronglyAgreePercentage,
                                                agreePercentage: historicalItem.agreePercentage,
                                                mildlyAgreePercentage: historicalItem.mildlyAgreePercentage,
                                                uncertainPercentage: historicalItem.uncertainPercentage,
                                                mildlyDisagreePercentage: historicalItem.mildlyDisagreePercentage,
                                                disagreePercentage: historicalItem.disagreePercentage,
                                                stronglyDisagreePercentage: historicalItem.stronglyDisagreePercentage,
                                                notApplicable: historicalItem.notApplicable,
                                            };
                                        } else if (currentField.name === "Store Partner Relationship") {
                                            currentItem.historicalPercentScores = {
                                                stronglyAgreePercentage: historicalItem.score["Strongly Agree"],
                                                agreePercentage: historicalItem.score["Agree"],
                                                disagreePercentage: historicalItem.score["Disagree"],
                                                stronglyDisagreePercentage: historicalItem.score["Strongly Disagree"],
                                                notApplicable: historicalItem.score["Not applicable"],
                                            };
                                        } else if (currentField.category === "Demographics") {
                                            currentItem.historicalDemographics = {
                                                ...historicalItem,
                                            };
                                        } else if (
                                            currentField.category === "Additional Questions" &&
                                            currentField.name !== "Additional Questions"
                                        ) {
                                            currentItem.historicalScore = historicalItem.score;
                                        } else if (
                                            currentField.name === "Notified the franchisor team of serious disagreement"
                                        ) {
                                            if (!Array.isArray(currentItem.historicalScore)) {
                                                currentItem.historicalScore = [];
                                            }

                                            currentItem.historicalScore.push({
                                                name: historicalScoreObjectName,
                                                percentScore: historicalItem.score.Yes,
                                            });
                                        } else {
                                            currentItem.historicalScore = historicalItem.percentScore;
                                        }
                                    }
                                } else {
                                    if (currentItem.name === historicalItem.name) {
                                        currentItem.historicalScore = historicalItem.percentScore;
                                        currentItem.historicalScore = historicalItem.percentScore;
                                    }
                                }

                                // Regional data
                                if (
                                    currentItem.hasOwnProperty("stateLevel") &&
                                    historicalItem.hasOwnProperty("stateLevel")
                                ) {
                                    if (currentItem.itemName === historicalItem.itemName) {
                                        currentItem.stateLevel.forEach((currentItemItem) => {
                                            historicalItem.stateLevel.forEach((historicalItemItem) => {
                                                if (currentItemItem.name === historicalItemItem.name) {
                                                    currentItemItem.historicalScore = historicalItemItem.percentScore;
                                                }
                                            });
                                        });
                                    }
                                }
                            });
                        });
                    }

                    if (!currentField.historicalScores) {
                        currentField.historicalScores = [];
                    }

                    if (historicalField.percentScore) {
                        currentField.historicalScores.push({
                            name: historicalScoreObjectName,
                            percentScore: historicalField.percentScore,
                        });
                    }

                    if (
                        !currentField.historicalScores.some(
                            (historicalScore) => historicalScore.name === report.currentYear
                        )
                    ) {
                        if (currentField.percentScore) {
                            currentField.historicalScores.push({
                                name: report.currentYear,
                                percentScore: currentField.percentScore,
                            });
                        }
                    }

                    // Regional data
                    if (
                        currentField.hasOwnProperty("overallScoreStateLevel") &&
                        historicalField.hasOwnProperty("overallScoreStateLevel")
                    ) {
                        currentField.overallScoreStateLevel.forEach((currentItemLevelItem) => {
                            historicalField.overallScoreStateLevel.forEach((historicalReportFieldItem) => {
                                if (currentItemLevelItem.name === historicalReportFieldItem.name) {
                                    currentItemLevelItem.historicalScore = historicalReportFieldItem.percentScore;

                                    if (!currentItemLevelItem.historicalScores) {
                                        currentItemLevelItem.historicalScores = [];
                                    }

                                    if (historicalReportFieldItem.percentScore) {
                                        currentItemLevelItem.historicalScores.push({
                                            name: historicalScoreObjectName,
                                            percentScore: historicalReportFieldItem.percentScore,
                                        });
                                    }

                                    if (
                                        !currentItemLevelItem.historicalScores.some(
                                            (historicalScore) => historicalScore.name === report.currentYear
                                        )
                                    ) {
                                        if (currentItemLevelItem.percentScore) {
                                            currentItemLevelItem.historicalScores.push({
                                                name: report.currentYear,
                                                percentScore: currentItemLevelItem.percentScore,
                                            });
                                        }
                                    }
                                }
                            });
                        });
                    }
                }
            });
        });
    }
}

function addRegionalData(report) {
    const sixDimensionData = JSON.parse(JSON.stringify(report.sixDimensions));

    sixDimensionData.forEach((dimensionData) => {
        report.sixDimensionsWithMemberTypes.push(dimensionData);
    });

    // Add regional data to sixDimensions
    addExtraDataToSixDimensions(report.sixDimensions, report.sixDimensionsRegional);

    // Check if we have member types
    if (report.sixDimensionsMemberTypes.length !== 0) {
        // Add member type data to sixDimensions
        addExtraDataToSixDimensions(report.sixDimensionsWithMemberTypes, report.sixDimensionsMemberTypes);
    }

    // Copy over overall generalSatisfaction data (overall score and sectorBenchmark) to
    // generalSatisfactionWithMemberTypes as they need to be compared to overall
    report.generalSatisfactionWithMemberTypes.push(JSON.parse(JSON.stringify(report.generalSatisfaction[0])));

    // Add regional data to generalSatisfaction
    if (report.sixDimensionsRegional.length !== 0) {
        addExtraDataToGeneralSatisfaction(
            report.sixDimensionsRegional,
            report.generalSatisfaction[0],
            "General Satisfaction - Regional"
        );
    }

    if (report.sixDimensionsMemberTypes.length !== 0) {
        // Add member type data to generalSatisfaction
        addExtraDataToGeneralSatisfaction(
            report.sixDimensionsMemberTypes,
            report.generalSatisfactionWithMemberTypes[0],
            "General Satisfaction - Member Type"
        );
    }

    if (report.memberQuestions.length !== 0) {
        report.memberQuestionsWithMemberTypes.push(JSON.parse(JSON.stringify(report.memberQuestions[0])));
    }

    if (report.memberQuestionsRegional.length !== 0) {
        addExtraDataToSixDimensions(report.memberQuestions, report.memberQuestionsRegional);
    }

    if (report.memberQuestionsWithMemberTypes.length !== 0) {
        addExtraDataToSixDimensions(report.memberQuestionsWithMemberTypes, report.memberQuestionsByMemberType);
    }

    addStateLevelDataToServices(report.services);
}

function addStateLevelDataToServices(services) {
    services.forEach((service) => {
        service.items.forEach((serviceItem) => {
            if (serviceItem.stateLevel) {
                serviceItem.stateLevel.forEach((stateLevelItem) => {
                    serviceItem.data.push({
                        name: stateLevelItem.name,
                        percentScore: stateLevelItem.percentScore,
                        category: stateLevelItem.name,
                        quintile: stateLevelItem.quintile,
                        historicalScore: stateLevelItem.historicalScore,
                        historicalEffectSize: stateLevelItem.historicalEffectSize,
                        benchmarkEffectSize: stateLevelItem.benchmarkEffectSize,
                        historicalSizeDifference: stateLevelItem.historicalSizeDifference,
                        benchmarkSizeDifference: stateLevelItem.benchmarkSizeDifference,
                    });
                });
            } else {
                if (service.data && service.categoryTitle === "Services - By Role") {
                    service.data.push({
                        name: serviceItem.name,
                        percentScore: serviceItem.percentScore,
                        category: serviceItem.name,
                        quintile: serviceItem.quintile,
                        historicalScore: serviceItem.historicalScore,
                        historicalEffectSize: serviceItem.historicalEffectSize,
                        benchmarkEffectSize: serviceItem.benchmarkEffectSize,
                        historicalSizeDifference: serviceItem.historicalSizeDifference,
                        benchmarkSizeDifference: serviceItem.benchmarkSizeDifference,
                    });
                }
            }
        });
    });
}

function addExtraDataToSixDimensions(sixDimensions, extraSixDimensionsData) {
    sixDimensions.forEach((dimension) => {
        extraSixDimensionsData.forEach((dimensionRegional) => {
            if (dimensionRegional.name.includes(dimension.name)) {
                dimensionRegional.overallScoreStateLevel.forEach((regionData) => {
                    if (!dimension.hasOwnProperty("regionalData")) {
                        dimension.regionalData = [];
                    }
                    dimension.regionalData.push({
                        name: regionData.name,
                        percentScore: regionData.percentScore,
                        category: regionData.name,
                        quintile: regionData.quintile,
                        historicalScore: regionData.historicalScore,
                        historicalEffectSize: regionData.historicalEffectSize,
                        benchmarkEffectSize: regionData.benchmarkEffectSize,
                        historicalSizeDifference: regionData.historicalSizeDifference,
                        benchmarkSizeDifference: regionData.benchmarkSizeDifference,
                    });
                });

                dimension.items.forEach((dimensionItem) => {
                    dimensionRegional.items.forEach((dimensionRegionalItem) => {
                        if (dimensionRegionalItem.itemName === dimensionItem.itemName) {
                            dimensionRegionalItem.stateLevel.forEach((regionItem) => {
                                dimensionItem.data.push({
                                    name: regionItem.name,
                                    percentScore: regionItem.percentScore,
                                    category: regionItem.name,
                                    quintile: regionItem.quintile,
                                    historicalScore: regionItem.historicalScore,
                                    historicalEffectSize: regionItem.historicalEffectSize,
                                    benchmarkEffectSize: regionItem.benchmarkEffectSize,
                                    historicalSizeDifference: regionItem.historicalSizeDifference,
                                    benchmarkSizeDifference: regionItem.benchmarkSizeDifference,
                                });
                            });
                        }
                    });
                });
            }
        });
    });
}

function addExtraDataToGeneralSatisfaction(extraGeneralSatisfactionData, generalSatisfaction, dimensionName) {
    extraGeneralSatisfactionData
        .find((dimension) => dimension.name === dimensionName)
        .items.forEach((regionItem) => {
            generalSatisfaction.data.push({
                name: regionItem.name,
                percentScore: regionItem.percentScore,
                category: regionItem.name,
                quintile: regionItem.quintile,
                historicalScore: regionItem.historicalScore,
                historicalEffectSize: regionItem.historicalEffectSize,
                benchmarkEffectSize: regionItem.benchmarkEffectSize,
                historicalSizeDifference: regionItem.historicalSizeDifference,
                benchmarkSizeDifference: regionItem.benchmarkSizeDifference,
            });
        });
}

function sortReportFieldForProcessing(reportField, benchmark, historical, report) {
    function addFieldToRespectiveProcess(itemName) {
        if (benchmark) {
            addBenchmarkPercentScoresToLocalReportData(itemName, reportField, report);
        } else {
            addReportFieldToLocalReportData(itemName, reportField, report);
        }
    }

    // Depending on reportField name or category we either add the reportField to aceSurveyReportData for the first
    // time or if benchmark data we add the percentScore
    if (reportField.category === "Mood Monitor" && SIX_DIMENSIONS_ITEM_NAMES.includes(reportField.name)) {
        addFieldToRespectiveProcess("sixDimensions");
    } else if (
        reportField.category === "Mood Monitor" &&
        (reportField.name === "General Satisfaction" || reportField.name === "Text")
    ) {
        addFieldToRespectiveProcess("generalSatisfaction");
    } else if (reportField.name === "ACE Score") {
        addFieldToRespectiveProcess("aceMindsetIndex");
    } else if (reportField.category === "Field Managers") {
        addFieldToRespectiveProcess("fieldManagers");
    } else if (reportField.category === "Country Managers") {
        addFieldToRespectiveProcess("fieldManagersCountry");
    } else if (reportField.name === "Each Response Proportions") {
        addFieldToRespectiveProcess("satisfactionProportions");
    } else if (reportField.category === "NPS" && reportField.name !== "ACE Score") {
        addFieldToRespectiveProcess("netPromoterScores");
    } else if (reportField.category === "Services") {
        addFieldToRespectiveProcess("services");
    } else if (reportField.category === "Services By Role Type") {
        addFieldToRespectiveProcess("services");
    } else if (reportField.category === "Mood Monitor" && reportField.name.includes("- Regional")) {
        addFieldToRespectiveProcess("sixDimensionsRegional");
    } else if (reportField.category === "Mood Monitor" && reportField.name.includes("- Member Type")) {
        addFieldToRespectiveProcess("sixDimensionsMemberTypes");
    } else if (reportField.category === "Qualitative") {
        addFieldToRespectiveProcess("qualitative");
    } else if (reportField.category === "Demographics" && reportField.name === "Follow Through") {
        addFieldToRespectiveProcess("followThrough");
    } else if (reportField.category === "Demographics" && reportField.name === "Other Demographics") {
        addFieldToRespectiveProcess("demographics");
    } else if (reportField.category === "Field Managers Level") {
        addFieldToRespectiveProcess("fieldManagersLevel");
    } else if (reportField.category === "Country Managers Level") {
        addFieldToRespectiveProcess("fieldManagersLevelCountry");
    } else if (reportField.category === "Additional Questions") {
        addFieldToRespectiveProcess("additionalQuestions");
    } else if (reportField.category === "Member Questions" && reportField.name.includes("- Regional")) {
        addFieldToRespectiveProcess("memberQuestionsRegional");
    } else if (reportField.category === "Member Questions" && reportField.name.includes("- Member Type")) {
        addFieldToRespectiveProcess("memberQuestionsByMemberType");
    } else if (reportField.category === "Member Questions") {
        addFieldToRespectiveProcess("memberQuestions");
    } else if (
        reportField.category === "Mood Monitor" &&
        reportField.name === "Notified the franchisor team of serious disagreement"
    ) {
        addFieldToRespectiveProcess("franchisorTeamNotificationOfSeriousDisagreement");
    }
}

function addReportFieldToLocalReportData(reportItemName, reportField, report) {
    const reportItem = report[reportItemName];
    reportItem.push(reportField);

    report[reportItemName] = reportItem;
}

function addPercentScoresToLocalReportData(report) {
    // Once reportFields have been added to our local report data set (aceSurveyReportData) above,
    // add percentScores as "current" data. This will make report data easily consumable in our React components
    Object.values(report).forEach((reportField) => {
        if (Array.isArray(reportField)) {
            reportField.forEach((reportItem) => {
                // Parent level items
                if (reportItem.hasOwnProperty("percentScore") || reportItem.hasOwnProperty("score")) {
                    addPercentScores(
                        reportField,
                        reportItem,
                        reportItem,
                        reportItem.category
                            ? ["Field Managers Level", "Country Managers Level"].includes(reportItem.category)
                                ? reportItem.name
                                : report.currentSurveyLabel
                            : report.currentSurveyLabel,
                        reportItem.name
                            ? [
                                  "General Satisfaction",
                                  "Business competence",
                                  "Relationship competence",
                                  "Average Services Ratings",
                                  "Member Questions",
                              ].includes(reportItem.name)
                                ? report.currentSurveyLabel
                                : reportItem.name
                            : report.currentSurveyLabel,
                        "current"
                    );
                }

                // Children level items
                if (reportItem.hasOwnProperty("items")) {
                    reportItem.items.forEach((item) => {
                        addPercentScores(
                            reportItem,
                            item,
                            item,
                            reportItem.category
                                ? ["Field Managers Level", "Country Managers Level"].includes(reportItem.category)
                                    ? reportItem.name
                                    : report.currentSurveyLabel
                                : report.currentSurveyLabel,
                            reportItem.category
                                ? ["Field Managers Level", "Country Managers Level"].includes(reportItem.category)
                                    ? reportItem.name
                                    : report.currentSurveyLabel
                                : report.currentSurveyLabel,
                            "current"
                        );
                    });
                }
            });
        }
    });
}

function addBenchmarkPercentScoresToLocalReportData(reportItemName, reportField, report) {
    const reportItem = report[reportItemName];

    // If we can match benchmark data reportField names with current reportField names we add it to that
    // same object
    reportItem.forEach((item) => {
        // Parent-level items
        if (
            (item.hasOwnProperty("percentScore") ||
                item.hasOwnProperty("score") ||
                item.categoryTitle === "Services - By Role") &&
            item.name === reportField.name
        ) {
            !item.hasOwnProperty("data") && (item.data = []);

            item.data.push({
                name: "Sector benchmark",
                percentScore: reportField.percentScore ? reportField.percentScore : reportField.score,
                category: "Sector benchmark",
                quintile: reportField.quintile ? reportField.quintile : null,
            });
        }

        // Children-level items
        if (item.hasOwnProperty("items") && reportField.hasOwnProperty("items")) {
            item.items.forEach((itemItem) => {
                reportField.items.forEach((reportFieldItem) => {
                    if (itemItem.itemName === reportFieldItem.itemName && reportField.name === item.name) {
                        addPercentScores(
                            reportField,
                            itemItem,
                            reportFieldItem,
                            "Sector benchmark",
                            "Sector benchmark",
                            "sectorBenchmark"
                        );
                    }
                });
            });
        }
    });
}

// This is where we define and construct our JSON structures for our charts, each chart has a specific structure
// it can consume that we extrapolate from the report data
function addPercentScores(reportField, recipientItem, donorItem, category, name, dataPropertyName) {
    if (
        (reportField.category === "NPS" || reportField.name === "Follow Through") &&
        reportField.name !== "ACE Score" &&
        reportField.name !== "NPS Score"
    ) {
        !recipientItem.hasOwnProperty("data") && (recipientItem.data = {});

        recipientItem.data[dataPropertyName] = {
            name: name,
            percentScores: {
                stronglyAgreePercentage: donorItem.stronglyAgreePercentage,
                agreePercentage: donorItem.agreePercentage,
                mildlyAgreePercentage: donorItem.mildlyAgreePercentage,
                uncertainPercentage: donorItem.uncertainPercentage,
                mildlyDisagreePercentage: donorItem.mildlyDisagreePercentage,
                disagreePercentage: donorItem.disagreePercentage,
                stronglyDisagreePercentage: donorItem.stronglyDisagreePercentage,
                notApplicable: donorItem.notApplicable,
            },
            category: category,
        };
    } else if (reportField.name === "Each Response Proportions") {
        !recipientItem.hasOwnProperty("data") && (recipientItem.data = []);

        recipientItem.data[dataPropertyName] = {
            name: name,
            percentScores: {
                overallDisagreePercentage: donorItem.overallDisagreePercentage,
                overallAgreePercentage: donorItem.overallAgreePercentage,
                stronglyAgreePercentage: donorItem.stronglyAgreePercentage,
                agreePercentage: donorItem.agreePercentage,
                disagreePercentage: donorItem.disagreePercentage,
                stronglyDisagreePercentage: donorItem.stronglyDisagreePercentage,
            },
            category: category,
        };
    } else if (reportField.name === "Other Demographics") {
        !recipientItem.hasOwnProperty("data") && (recipientItem.data = []);

        recipientItem.data[dataPropertyName] = {
            name: name,
            itemName: donorItem.itemName,
            itemText: donorItem.itemText,
            responseCount: donorItem.responseCount,
            score: donorItem.score,
            category: category,
        };
    } else if (reportField.name === "Support and Succession Planning") {
        !recipientItem.hasOwnProperty("data") && (recipientItem.data = []);

        recipientItem.data[dataPropertyName] = {
            name: name,
            percentScores: {
                stronglyAgreePercentage: donorItem.score["Strongly Agree"],
                agreePercentage: donorItem.score["Agree"],
                uncertainPercentage: donorItem.score["Neutral"],
                disagreePercentage: donorItem.score["Disagree"],
                stronglyDisagreePercentage: donorItem.score["Strongly Disagree"],
            },
            category: category,
        };
    } else if (reportField.name === "Store Partner Relationship") {
        !recipientItem.hasOwnProperty("data") && (recipientItem.data = []);

        recipientItem.data[dataPropertyName] = {
            name: name,
            percentScores: {
                stronglyAgreePercentage: donorItem.score["Strongly Agree"],
                agreePercentage: donorItem.score["Agree"],
                disagreePercentage: donorItem.score["Disagree"],
                stronglyDisagreePercentage: donorItem.score["Strongly Disagree"],
                notApplicable: donorItem.score["Not applicable"],
            },
            category: category,
        };
    } else {
        !recipientItem.hasOwnProperty("data") && (recipientItem.data = []);
        if (donorItem.percentScore || donorItem.score) {
            recipientItem.data.push({
                name: name,
                percentScore: donorItem.percentScore ? donorItem.percentScore : donorItem.score,
                category: category,
                quintile: donorItem.quintile,
                historicalScore: donorItem.historicalScore,
                historicalEffectSize: donorItem.historicalEffectSize,
                benchmarkEffectSize: donorItem.benchmarkEffectSize,
                historicalSizeDifference: donorItem.historicalSizeDifference,
                benchmarkSizeDifference: donorItem.benchmarkSizeDifference,
                responseCount: donorItem.responseCount,
                type: donorItem.type,
            });
        }
    }
}
