import { useEffect, useState } from "react";

export default function ScaleAxis(props) {
    const { top, percentage, labels, percentageLabels, percentageAxisMargin, neverHide } = props;

    const [currentLabels, setCurrentLabels] = useState({
        stronglyDisagree: "Strongly disagree",
        disagree: "Disagree",
        slightlyDisagree: "Slightly disagree",
        uncertain: "Uncertain",
        slightlyAgree: "Slightly agree",
        agree: "Agree",
        stronglyAgree: "Strongly agree",
    });

    const [currentPercentageLabels, setPercentageLabels] = useState({
        first: "-100",
        second: "-50",
        third: "0",
        fourth: "50",
        fifth: "100",
    });

    const AxisLabels = () => {
        return (
            <div
                className={
                    "flex-row w-full font-title text-xs xl:text-sm text-slate-500 font-medium " +
                    (neverHide ? "flex" : "hidden md:flex")
                }
            >
                <div className="w-20-percentage text-center text-red-700 opacity-70">
                    {currentLabels.stronglyDisagree}
                </div>
                <div className="w-15-percentage text-center text-red-700 opacity-70">{currentLabels.disagree}</div>
                <div className="w-8-percentage text-center">
                    <span className="hidden">{currentLabels.slightlyDisagree}</span>
                </div>
                <div className="w-14-percentage text-center">{currentLabels.uncertain}</div>
                <div className="w-8-percentage text-center">
                    <span className="hidden">{currentLabels.slightlyAgree}</span>
                </div>
                <div className="w-15-percentage text-center text-green-700 opacity-70">{currentLabels.agree}</div>
                <div className="w-20-percentage text-center text-green-700 opacity-70">
                    {currentLabels.stronglyAgree}
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (labels) {
            setCurrentLabels({
                stronglyDisagree: labels.stronglyDisagree,
                disagree: labels.disagree,
                slightlyDisagree: labels.slightlyDisagree,
                uncertain: labels.uncertain,
                slightlyAgree: labels.slightlyAgree,
                agree: labels.agree,
                stronglyAgree: labels.stronglyAgree,
            });
        }

        if (percentageLabels) {
            setPercentageLabels({
                first: percentageLabels.first,
                second: percentageLabels.second,
                third: percentageLabels.third,
                fourth: percentageLabels.fourth,
                fifth: percentageLabels.fifth,
            });
        }
    }, [labels, percentageLabels]);

    return top ? (
        percentage ? (
            <div
                className={
                    "flex-col gap-1.5 print:break-inside-avoid-page " +
                    (percentageAxisMargin ? "mr-14 " : " ") +
                    (neverHide ? "flex" : "hidden md:flex")
                }
            >
                <div className="flex flex-row w-full font-title text-xs xl:text-sm text-slate-400 font-medium">
                    <div className="w-25-percentage text-left">{currentPercentageLabels.first}</div>
                    <div className="w-25-percentage text-left">
                        <span className="-ml-2"> {currentPercentageLabels.second}</span>
                    </div>
                    <div className="w-25-percentage text-left">
                        <span className="-ml-2"> {currentPercentageLabels.third}</span>
                    </div>
                    <div className="w-25-percentage flex justify-between">
                        <div className="text-left">
                            <span className="-ml-2"> {currentPercentageLabels.fourth}</span>
                        </div>
                        <div className="text-right">{currentPercentageLabels.fifth}</div>
                    </div>
                </div>
                <div className="flex flex-row w-full">
                    <div className="w-25-percentage border-slate-400 border-dashed border-2 border-b-0 border-r-0 h-3.5" />
                    <div className="w-25-percentage border-slate-400 border-dashed border-2 border-b-0 border-r-0 h-3.5" />
                    <div className="w-25-percentage border-slate-400 border-dashed border-2 border-b-0 border-r-0 h-3.5" />
                    <div className="w-25-percentage border-slate-400 border-dashed border-2 border-b-0 h-3.5" />
                </div>
            </div>
        ) : (
            <div
                className={
                    "flex-col gap-1.5 mr-14 print:break-inside-avoid-page " + (neverHide ? "flex" : "hidden md:flex")
                }
            >
                <AxisLabels />
                <div className="flex flex-row w-full">
                    <div className="w-20-percentage border-red-600 border-dashed border-2 border-b-0 border-r-0 h-3.5 opacity-70" />
                    <div className="w-15-percentage border-red-600 border-dashed border-2 border-b-0  h-3.5 opacity-70" />
                    <div className="w-11-percentage border-slate-400 border-dashed border-2 border-b-0 border-r-0 border-l-0 h-3.5" />
                    <div className="w-8-percentage border-slate-400 border-dashed border-2 border-b-0 h-3.5" />
                    <div className="w-11-percentage border-slate-400 border-dashed border-2 border-b-0 border-r-0 border-l-0 h-3.5" />
                    <div className="w-15-percentage border-green-600 border-dashed border-2 border-b-0 h-3.5 opacity-70" />
                    <div className="w-20-percentage border-green-600 border-dashed border-2 border-b-0 border-l-0 h-3.5 opacity-70" />
                </div>
            </div>
        )
    ) : percentage ? (
        <div
            className={
                "flex-col gap-1.5 print:break-inside-avoid-page " +
                (percentageAxisMargin ? "mr-14" : " ") +
                (neverHide ? "flex" : "hidden md:flex")
            }
        >
            <div className="flex flex-row w-full">
                <div className="w-25-percentage border-slate-400 border-dashed border-2 border-t-0 border-r-0 h-3.5" />
                <div className="w-25-percentage border-slate-400 border-dashed border-2 border-t-0 border-r-0 h-3.5" />
                <div className="w-25-percentage border-slate-400 border-dashed border-2 border-t-0 border-r-0 h-3.5" />
                <div className="w-25-percentage border-slate-400 border-dashed border-2 border-t-0 h-3.5" />
            </div>
            <div className="flex flex-row w-full font-title text-xs xl:text-sm text-slate-400 font-medium">
                <div className="w-25-percentage text-left">{currentPercentageLabels.first}</div>
                <div className="w-25-percentage text-left">
                    <span className="-ml-2"> {currentPercentageLabels.second}</span>
                </div>
                <div className="w-25-percentage text-left">
                    <span className="-ml-2"> {currentPercentageLabels.third}</span>
                </div>
                <div className="w-25-percentage flex justify-between">
                    <div className="text-left">
                        <span className="-ml-2"> {currentPercentageLabels.fourth}</span>
                    </div>
                    <div className="text-right">{currentPercentageLabels.fifth}</div>
                </div>
            </div>
        </div>
    ) : (
        <div
            className={"flex-col gap-1.5 mr-14 print:break-inside-avoid-page" + (neverHide ? "flex" : "hidden md:flex")}
        >
            <div className="flex flex-row w-full">
                <div className="w-20-percentage border-red-600 border-dashed border-2 border-t-0 border-r-0 h-3.5 opacity-70" />
                <div className="w-15-percentage border-red-600 border-dashed border-2 border-t-0  h-3.5 opacity-70" />
                <div className="w-11-percentage border-slate-400 border-dashed border-2 border-t-0 border-r-0 border-l-0 h-3.5" />
                <div className="w-8-percentage border-slate-400 border-dashed border-2 border-t-0 h-3.5" />
                <div className="w-11-percentage border-slate-400 border-dashed border-2 border-t-0 border-r-0 border-l-0 h-3.5" />
                <div className="w-15-percentage border-green-600 border-dashed border-2 border-t-0 h-3.5 opacity-70" />
                <div className="w-20-percentage border-green-600 border-dashed border-2 border-t-0 border-l-0 h-3.5 opacity-70" />
            </div>
            <AxisLabels />
        </div>
    );
}
